/*
@File: Novine Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Top Panel Area CSS
** - Top Header Area CSS
** - Navbar Area CSS
** - Modal CSS
** - Main Banner Area CSS
** - About Area CSS
** - Offer Area CSS
** - All Products Area CSS
** - Products QuickView Modal Area CSS
** - Category Products Area CSS
** - Trending Products Area CSS
** - Best Sellers Area CSS~
** - Facility Area CSS
** - Testimonials Area CSS
** - Products Offer Area CSS
** - News Area CSS
** - News Details Area CSS
** - Subscribe Area CSS
** - Partner Area CSS
** - Instagram Area CSS
** - Page Title Area CSS
** - Products Collections Area CSS
** - Woocommerce Sidebar Area CSS
** - Products Filter Modal Area CSS
** - Gallery Area CSS
** - Look Book Area CSS
** - Sizing Guide Area CSS
** - 404 Error Area CSS
** - FAQs Area CSS
** - Contact Area CSS
** - Products Details Area CSS
** - Pagination Area CSS
** - Sidebar Area CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Compare Area CSS
** - Login Area CSS
** - Signup Area CSS
** - Customer Service Area CSS
** - Footer Area CSS
** - Go Top CSS
*/
/*================================================
Default CSS
=================================================*/
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


:root {
  scroll-behavior: inherit;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
}

p {
  color: #666666;
  line-height: 1.8;
  font-size: 14px;
  margin-bottom: 15px;
}

p:last-child {
  margin-bottom: 0;
}

a {
  display: block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.ptb-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-30 {
  padding-bottom: 30px;
}

.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

.form-check-input {
  margin-top: 3px;
}

/*section-title*/
.section-title {
  margin-bottom: 40px;
  text-align: center;
  background-color: #fcfbfb;
  padding: 15px;
}

.section-title h2 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 20px;
  position: relative;
  padding-left: 20px;
  font-weight: 600;
}

.section-title h2 .dot {
  position: absolute;
  top: 4px;
  left: 0;
  width: 12px;
  height: 12px;
  border: 1px solid #222222;
}

.section-title h2 .dot::before {
  position: absolute;
  top: 0;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  background: #222222;
  margin: 1px;
}

.section-title.without-bg {
  padding: 0;
  background-color: transparent;
}

/*btn(primary&light)*/
.btn {
  border: 1px solid transparent;
  padding: 11px 25px;
  line-height: initial;
  border-radius: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}

.btn.disabled,
.btn:disabled {
  opacity: 1;
}

.btn-primary {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: transparent;
  color: #222222;
  border-color: #222222;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-primary:hover,
.btn-primary:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: transparent;
  color: #222222;
  border-color: #222222;
}

.btn-light {
  background-color: transparent;
  color: #222222;
  border-color: #222222;
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: transparent;
  color: #222222;
  border-color: #222222;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-light:hover,
.btn-light:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

/*owl-carousel-btn*/
.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 20px;
  line-height: initial;
}

.owl-theme .owl-dots .owl-dot {
  outline: 0;
}

.owl-theme .owl-dots .owl-dot span {
  margin: 0 3px !important;
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
  border-radius: 50% !important;
  width: 10px !important;
  height: 10px !important;
  background: #d2d2d2 !important;
}

.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot.active span {
  background: #222222 !important;
}

/*form-control*/
.form-control {
  background-color: #f5f5f5;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  font-size: 14px;
  line-height: initial;
  color: #222222;
  border: none;
  border-radius: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-control:focus {
  outline: 0;
  background-color: #f5f5f5;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.form-control::-webkit-input-placeholder {
  color: #999999;
}

.form-control:-ms-input-placeholder {
  color: #999999;
}

.form-control::-ms-input-placeholder {
  color: #999999;
}

.form-control::placeholder {
  color: #999999;
}

/*================================================
Top Panel Area CSS
=================================================*/
.top-panel {
  background-color: #222222;
  text-align: center;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: auto;
  padding-top: 13px;
  padding-bottom: 13px;
}

.top-panel .panel-content {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-panel .panel-content p {
  margin-bottom: 0;
  line-height: initial;
  color: #ffffff;
  font-size: 17px;
  font-style: italic;
}

.top-panel .panel-content p b {
  font-size: 20px;
}

.top-panel .panel-content p a {
  display: inline-block;
  line-height: initial;
  color: #ffffff;
  position: relative;
  font-weight: 700;
  font-style: normal;
}

.top-panel .panel-content p a::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 2px;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #ffffff;
}

.top-panel .panel-content p a:hover::before,
.top-panel .panel-content p a:focus::before {
  width: 100%;
}

.top-panel .container {
  position: relative;
}

.top-panel .panel-close-btn {
  position: absolute;
  right: 15px;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0.9;
}

.top-panel .panel-close-btn:hover,
.top-panel .panel-close-btn:focus {
  color: #ffffff;
  opacity: 1;
}

.top-panel.hide {
  opacity: 0;
  visibility: hidden;
  height: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0;
}

.top-panel.hide .panel-content {
  height: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/*================================================
Top Header Area CSS
=================================================*/
.top-header {
  background-color: #f1f1f1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-header .container-fluid {
  padding-left: 35px;
  padding-right: 35px;
}

.top-header-nav {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.top-header-nav li {
  display: inline-block;
  position: relative;
  margin-right: 17px;
  line-height: initial;
}

.top-header-nav li a {
  color: #222222;
  font-size: 13px;
  line-height: initial;
  position: relative;
}

.top-header-nav li a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-header-nav li a:hover,
.top-header-nav li a:focus {
  color: #222222;
}

.top-header-nav li a:hover::before,
.top-header-nav li a:focus::before {
  width: 100%;
}

.top-header-nav li::before {
  content: '';
  position: absolute;
  right: -9px;
  top: 0.5px;
  width: 1px;
  height: 12px;
  background: #888888;
}

.top-header-nav li:last-child {
  margin-right: 0;
}

.top-header-nav li:last-child::before {
  display: none;
}

.top-header-right-nav {
  text-align: right;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.top-header-right-nav li {
  display: inline-block;
  position: relative;
  margin-right: 17px;
  line-height: initial;
}

.top-header-right-nav li a {
  color: #222222;
  font-size: 13px;
  line-height: initial;
  position: relative;
}

.top-header-right-nav li a i {
  margin-left: 2px;
}

.top-header-right-nav li a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-header-right-nav li a:hover,
.top-header-right-nav li a:focus {
  color: #222222;
}

.top-header-right-nav li a:hover::before,
.top-header-right-nav li a:focus::before {
  width: 100%;
}

.top-header-right-nav li::before {
  content: '';
  position: absolute;
  right: -9px;
  top: 0.5px;
  width: 1px;
  height: 12px;
  background: #888888;
}

.top-header-right-nav li:last-child {
  margin-right: 0;
}

.top-header-right-nav li:last-child::before {
  display: none;
}

.top-header-right-nav .languages-list select {
  background: transparent;
  outline: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area .is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-brand {
  position: relative;
  top: -1px;
  padding-top: 0;
  padding-bottom: 0;
}

.comero-nav {
  background-color: #ffffff;
  padding-top: 3px;
  padding-bottom: 3px;
}

.comero-nav .navbar {
  position: inherit;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.comero-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.comero-nav .navbar .navbar-toggler {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.comero-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}

.comero-nav .navbar .navbar-nav .nav-item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}

.comero-nav .navbar .navbar-nav .nav-item.p-relative {
  position: relative;
}

.comero-nav .navbar .navbar-nav .nav-item a {
  font-size: 14px;
  color: #222222;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.comero-nav .navbar .navbar-nav .nav-item a:hover,
.comero-nav .navbar .navbar-nav .nav-item a:focus,
.comero-nav .navbar .navbar-nav .nav-item a.active {
  color: #222222;
}

.comero-nav .navbar .navbar-nav .nav-item a i {
  font-size: 11px;
}

.comero-nav .navbar .navbar-nav .nav-item:hover a,
.comero-nav .navbar .navbar-nav .nav-item:focus a,
.comero-nav .navbar .navbar-nav .nav-item.active a {
  color: #222222;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  position: relative;
  padding: 0;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 8px 15px;
  margin: 0;
  display: block;
  color: #666666;
  font-size: 14px;
  position: relative;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #222222;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li a .new {
  background-color: #dc3545;
  color: #fff;
  font-size: 8px;
  padding: 2px 5px;
  border-radius: 4px;
  position: relative;
  top: -8px;
  left: 7px;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #666666;
  text-transform: capitalize;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #222222;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li {
  position: relative;
  padding: 0;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  text-transform: capitalize;
  padding: 8px 15px;
  margin: 0;
  display: block;
  color: #666666;
  font-size: 14px;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #222222;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: auto;
  right: 240px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #222222;
  text-transform: capitalize;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #222222;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #222222;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #222222;
}

.comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.comero-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.comero-nav .navbar .navbar-nav .nav-item:last-child .dropdown-menu {
  left: auto;
  right: 0;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu {
  padding: 35px 0;
  position: absolute;
  top: auto;
  left: 0;
  margin-top: 23px;
  width: 100%;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .submenu-title {
  color: #222222;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li {
  margin-top: 12px;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li:first-child {
  margin-top: 0;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a {
  padding: 0;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu.top-brands {
  margin-left: -5px;
  margin-top: -5px;
  margin-right: -5px;
  margin-bottom: -5px;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu.top-brands li {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu.top-brands li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border: 1px solid #eeeeee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu.top-brands li:hover::before {
  border-color: #222222;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products {
  overflow: hidden;
  position: relative;
  text-align: center;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products .category {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products .category h4 {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:hover img,
.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:focus img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:hover .category,
.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:focus .category {
  bottom: 10px;
}

.comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:not(:first-child) {
  margin-top: 15px;
}

.comero-nav .others-option {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.comero-nav .others-option .option-item {
  display: inline-block;
  position: relative;
  margin-right: 17px;
  line-height: initial;
}

.comero-nav .others-option .option-item a {
  color: #222222;
  text-transform: uppercase;
  position: relative;
}

.comero-nav .others-option .option-item a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.comero-nav .others-option .option-item a:hover,
.comero-nav .others-option .option-item a:focus {
  color: #222222;
}

.comero-nav .others-option .option-item a:hover::before,
.comero-nav .others-option .option-item a:focus::before {
  width: 100%;
}

.comero-nav .others-option .option-item::before {
  content: '';
  position: absolute;
  right: -9px;
  top: 0.5px;
  width: 1px;
  height: 12px;
  background: #888888;
}

.comero-nav .others-option .option-item:last-child {
  margin-right: 0;
}

.comero-nav .others-option .option-item:last-child::before {
  display: none;
}

.comero-nav .others-option .option-item .search-btn {
  cursor: pointer;
}

.comero-nav .others-option .option-item .close-btn {
  cursor: pointer;
  display: none;
}

.comero-nav .others-option .option-item .close-btn.active {
  display: block;
}

.navbar-toggler {
  border: none;
  background: #eee !important;
  padding: 10px;
  border-radius: 0;
}

.navbar-toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-toggler .icon-bar {
  width: 28px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #333;
  height: 2px;
  display: block;
}

.navbar-toggler .top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  margin: 6px 0;
}

.navbar-toggler .bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}

.navbar-toggler.collapsed .top-bar {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  left: 0;
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  left: 0;
}

/*================================================
Modal CSS
=================================================*/
.shoppingCartModal.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.shoppingCartModal.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.shoppingCartModal.modal.right.fade .modal-dialog {
  right: -420px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.shoppingCartModal.modal.right.fade.show .modal-dialog {
  right: 0;
}

.shoppingCartModal .modal-content {
  border-radius: 0;
  border: none;
}

.shoppingCartModal .modal-content .modal-body {
  padding: 25px 15px;
}

.shoppingCartModal .modal-content .modal-body h3 {
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.shoppingCartModal .modal-content .modal-body .product-cart-content {
  color: #666666;
}

.shoppingCartModal .modal-content .modal-body .product-cart-content .product-cart {
  position: relative;
  border-bottom: 1px dashed #eeeeee;
  margin-bottom: 15px;
  padding-left: 60px;
  padding-bottom: 15px;
}

.shoppingCartModal .modal-content .modal-body .product-cart-content .product-cart .product-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
}

.shoppingCartModal .modal-content .modal-body .product-cart-content .product-cart .product-content h3 {
  font-size: 13px;
  text-transform: initial;
  margin-bottom: 5px;
}

.shoppingCartModal .modal-content .modal-body .product-cart-content .product-cart .product-content h3 a {
  color: #222222;
}

.shoppingCartModal .modal-content .modal-body .product-cart-content .product-cart .product-content span {
  font-size: 12px;
  display: block;
  margin-bottom: 3px;
  color: #666666;
}

.shoppingCartModal .modal-content .modal-body .product-cart-content .product-cart .product-content .product-price span {
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  color: #666666;
}

.shoppingCartModal .modal-content .modal-body .product-cart-content .product-cart .product-content .product-price span.price {
  font-size: 14px;
  color: #222222;
}

.shoppingCartModal .modal-content .modal-body .product-cart-content .product-cart .product-content .product-price span:nth-child(2) {
  margin-left: 2px;
  margin-right: 2px;
}

.shoppingCartModal .modal-content .modal-body .product-cart-subtotal {
  overflow: hidden;
  margin-bottom: 15px;
  margin-top: 15px;
}

.shoppingCartModal .modal-content .modal-body .product-cart-subtotal span {
  text-transform: uppercase;
  display: inline-block;
  font-size: 15px;
}

.shoppingCartModal .modal-content .modal-body .product-cart-subtotal span.subtotal {
  float: right;
}

.shoppingCartModal .modal-content .modal-body .product-cart-btn .btn {
  display: block;
}

.shoppingCartModal .modal-content .modal-body .product-cart-btn .btn.btn-light {
  margin-top: 12px;
}

.shoppingCartModal .close {
  color: #222222;
  font-size: 30px;
  outline: 0 !important;
  line-height: initial;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  right: 15px;
  top: 15px;
  background: transparent;
  border: none;
  z-index: 1;
}

.shoppingCartModal .close:not(:disabled):not(.disabled):hover,
.shoppingCartModal .close:not(:disabled):not(.disabled):focus {
  color: #222222;
  text-decoration: none;
  opacity: 1;
}

.search-overlay {
  display: none;
}

.search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 26px;
}

.search-overlay.search-popup .search-form {
  position: relative;
}

.search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 40px;
  line-height: initial;
  border: 1px solid #eeeeee;
  padding-left: 10px;
  color: #222222;
  outline: 0;
}

.search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  background: transparent;
  border: none;
  width: 40px;
  outline: 0;
  color: #666666;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.search-overlay.search-popup .search-form .search-button:hover,
.search-overlay.search-popup .search-form .search-button:focus {
  color: #222222;
}

.bts-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9991;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.bts-popup.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.bts-popup.is-visible .bts-popup-container {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bts-popup .bts-popup-container {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 1;
  max-width: 600px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: none;
  text-align: center;
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding: 50px 130px;
  overflow: hidden;
}

.bts-popup .bts-popup-container .bts-popup-button {
  padding: 5px 25px;
  border: 2px solid #222222;
  display: inline-block;
  margin-bottom: 10px;
}

.bts-popup .bts-popup-container a {
  color: #222222;
  text-decoration: none;
  text-transform: uppercase;
}

.bts-popup .bts-popup-container .bts-popup-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
}

.bts-popup .bts-popup-container .bts-popup-close::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 8px;
}

.bts-popup .bts-popup-container .bts-popup-close::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 6px;
  top: 13px;
}

.bts-popup .bts-popup-container .bts-popup-close::before,
.bts-popup .bts-popup-container .bts-popup-close::after {
  content: '';
  position: absolute;
  top: 12px;
  width: 16px;
  height: 3px;
  background-color: #222222;
}

.bts-popup .bts-popup-container h3 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
}

.bts-popup .bts-popup-container h3 p {
  margin-bottom: 25px;
  font-size: 13px;
}

.bts-popup .bts-popup-container ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}

.bts-popup .bts-popup-container ul li {
  display: inline-block;
  margin-right: 5px;
}

.bts-popup .bts-popup-container ul li a {
  width: 33px;
  height: 33px;
  background-color: #222222;
  color: #ffffff;
  line-height: 35px;
  border-radius: 50%;
}

.bts-popup .bts-popup-container ul li a.facebook {
  background-color: #3b5998;
  color: #ffffff;
}

.bts-popup .bts-popup-container ul li a.twitter {
  background-color: #1da1f2;
  color: #ffffff;
}

.bts-popup .bts-popup-container ul li a.linkedin {
  background-color: #0077b5;
  color: #ffffff;
}

.bts-popup .bts-popup-container ul li a.instagram {
  background-color: #e1306c;
  color: #ffffff;
}

.bts-popup .bts-popup-container .img-box img:nth-child(1) {
  position: absolute;
  left: -45px;
  bottom: 0;
  z-index: -1;
  width: 185px;
}

.bts-popup .bts-popup-container .img-box img:nth-child(2) {
  position: absolute;
  right: -45px;
  bottom: 0;
  z-index: -1;
  width: 185px;
}

.bts-popup .bts-popup-container form {
  position: relative;
}

.bts-popup .bts-popup-container form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 45px;
  border: none;
  background-color: #222222;
  color: #ffffff;
  outline: 0;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0 20px;
}

.bts-popup .bts-popup-container form button:hover,
.bts-popup .bts-popup-container form button:focus {
  background-color: #000000;
}

.bts-popup .bts-popup-container form .validation-danger {
  color: red;
  margin-top: 10px;
}

.bts-popup .bts-popup-container form .validation-success {
  margin-top: 10px;
}

.modal {
  background-color: #0000004d;
}

.shoppingWishlistModal.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.shoppingWishlistModal.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.shoppingWishlistModal.modal.right.fade .modal-dialog {
  right: -420px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.shoppingWishlistModal.modal.right.fade.show .modal-dialog {
  right: 0;
}

.shoppingWishlistModal .modal-content {
  border-radius: 0;
  border: none;
}

.shoppingWishlistModal .modal-content .modal-body {
  padding: 25px 15px;
}

.shoppingWishlistModal .modal-content .modal-body h3 {
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.shoppingWishlistModal .modal-content .modal-body .product-cart-content .product-cart {
  position: relative;
  border-bottom: 1px dashed #eeeeee;
  margin-bottom: 15px;
  padding-left: 60px;
  padding-bottom: 15px;
}

.shoppingWishlistModal .modal-content .modal-body .product-cart-content .product-cart .product-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
}

.shoppingWishlistModal .modal-content .modal-body .product-cart-content .product-cart .product-content h3 {
  font-size: 13px;
  text-transform: initial;
  margin-bottom: 5px;
}

.shoppingWishlistModal .modal-content .modal-body .product-cart-content .product-cart .product-content h3 a {
  color: #222222;
}

.shoppingWishlistModal .modal-content .modal-body .product-cart-content .product-cart .product-content span {
  font-size: 12px;
  display: block;
  margin-bottom: 3px;
  color: #666666;
}

.shoppingWishlistModal .modal-content .modal-body .product-cart-content .product-cart .product-content .product-price span {
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  color: #666666;
}

.shoppingWishlistModal .modal-content .modal-body .product-cart-content .product-cart .product-content .product-price span.price {
  font-size: 14px;
  color: #222222;
}

.shoppingWishlistModal .modal-content .modal-body .product-cart-btn .btn {
  display: block;
}

.shoppingWishlistModal .modal-content .modal-body .product-cart-btn .btn.btn-light {
  margin-top: 15px;
}

.shoppingWishlistModal .close {
  color: #222222;
  font-size: 30px;
  outline: 0 !important;
  line-height: initial;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  border: none;
  background-color: transparent;
  opacity: .7;
}

.shoppingWishlistModal .close:not(:disabled):not(.disabled):hover,
.shoppingWishlistModal .close:not(:disabled):not(.disabled):focus {
  color: #222222;
  text-decoration: none;
  opacity: 1;
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  height: 600px;
  position: relative;
  z-index: 1;
  background-color: #f1f1f1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.main-banner.item-bg1 {
  background-image: url(../images/main-banner1.jpg);
}

.main-banner.item-bg2 {
  background-image: url(../images/main-banner2.jpg);
}

.main-banner.item-bg3 {
  background-image: url(../images/main-banner3.jpg);
}

.main-banner.item-bg4 {
  background-image: url(../images/main-banner4.jpg);
}

.main-banner-content {
  max-width: 520px;
}

.main-banner-content span {
  font-size: 14px;
  color: #666666;
  display: block;
}

.main-banner-content h1 {
  font-size: 34px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 15px;
}

.main-banner-content p {
  line-height: initial;
  font-size: 18px;
  color: #222222;
  margin-bottom: 35px;
}

.main-banner-content .btn-primary {
  margin-right: 15px;
}

.main-banner-content.white-color span {
  color: #ffffff;
}

.main-banner-content.white-color h1 {
  color: #ffffff;
}

.main-banner-content.white-color p {
  color: #ffffff;
}

.main-banner-content.white-color .btn-primary:hover,
.main-banner-content.white-color .btn-primary:focus {
  background-color: #ffffff;
  color: #222222;
  border-color: #ffffff;
}

.home-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.home-slides.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 160px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  outline: 0;
  width: 45px;
  height: 45px;
  line-height: 39px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #959595;
  color: #ffffff;
  border-radius: 50%;
  opacity: 1;
  visibility: hidden;
}

.home-slides.owl-theme .owl-nav [class*=owl-]:hover,
.home-slides.owl-theme .owl-nav [class*=owl-]:focus {
  background-color: #222222;
}

.home-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 160px;
}

.home-slides.owl-theme:hover .owl-nav [class*=owl-] {
  opacity: 1;
  visibility: visible;
  left: 140px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.home-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 140px;
}

.home-slides.owl-theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}

.main-banner-two {
  padding-top: 30px;
  position: relative;
  z-index: 1;
  background-color: #f1f1f1;
}

.main-banner-three {
  height: 820px;
}

.banner-image {
  text-align: center;
  position: relative;
  z-index: 1;
}

.banner-image img {
  width: unset !important;
  display: inline-block !important;
}

.banner-image .circle {
  width: 470px;
  height: 470px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 5%;
  right: 0;
  margin: 0 auto;
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
}

.about-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.about-content .signature {
  margin-top: 35px;
}

.about-image {
  position: relative;
  padding-bottom: 160px;
}

.about-image .about-img1 {
  position: relative;
  right: -100px;
}

.about-image .about-img2 {
  position: absolute;
  left: 20px;
  bottom: 0;
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/*================================================
Offer Area CSS
=================================================*/
.offer-area .section-title {
  background-color: transparent;
  padding: 0;
}

.offer-area .section-title h2 {
  padding-left: 0;
  font-weight: 700;
}

.offer-area .section-title h2 .dot {
  display: none;
}

.offer-box {
  overflow: hidden;
  position: relative;
  text-align: center;
}

.offer-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
  background-color: #ffffff;
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 2;
}

.offer-box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%;
  background-color: #ffffff;
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 2;
}

.offer-box .category {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 0;
  bottom: 25px;
  border: 1px solid #ececec;
  padding: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.offer-box .category::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ededed;
  opacity: 0.94;
  z-index: 1;
  margin: 6px;
}

.offer-box .category h4 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.offer-box img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.offer-box .box-inner {
  position: absolute;
  top: 50px;
  left: 0;
  padding: 60px 15px;
  right: 0;
  bottom: 0;
  border: 1px solid #ededed;
  margin: 30px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.offer-box .box-inner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ededed;
  opacity: 0.94;
  z-index: 1;
  margin: 6px;
  z-index: -1;
}

.offer-box .box-inner h3 {
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.offer-box .box-inner ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.offer-box .box-inner ul li {
  margin-bottom: 10px;
}

.offer-box .box-inner ul li:last-child {
  margin-bottom: 0;
}

.offer-box .box-inner ul li a {
  font-size: 13px;
  color: #666666;
  position: relative;
  display: inline-block;
}

.offer-box .box-inner ul li a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.offer-box .box-inner ul li a:hover,
.offer-box .box-inner ul li a:focus {
  color: #222222;
}

.offer-box .box-inner ul li a:hover::before,
.offer-box .box-inner ul li a:focus::before {
  width: 100%;
}

.offer-box:hover img,
.offer-box:focus img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.offer-box:hover::after,
.offer-box:focus::after {
  left: 100%;
}

.offer-box:hover::before,
.offer-box:focus::before {
  left: -100%;
}

.offer-box:hover .category,
.offer-box:focus .category {
  opacity: 0;
  visibility: hidden;
  bottom: 0;
}

.offer-box:hover .box-inner,
.offer-box:focus .box-inner {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.single-offer-box {
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 2px;
}

.single-offer-box::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222222;
  opacity: .50;
  z-index: 2;
  border-radius: 2px;
}

.single-offer-box img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 2px;
}

.single-offer-box .offer-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;
}

.single-offer-box .offer-content h3 {
  color: #ffffff;
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 6px;
}

.single-offer-box .offer-content span {
  display: block;
  color: #ffffff;
}

.single-offer-box a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.single-offer-box:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.offer-slides.owl-theme .owl-dots {
  margin-top: 30px;
}

.offer-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.offer-slides.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: -5px;
  top: 0;
  height: 83.4%;
  width: 34px;
  background-color: #dedddc;
  font-size: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: 0;
  border-radius: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  color: #222222;
}

.offer-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #222222;
  color: #ffffff;
}

.offer-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -5px;
}

.offer-slides.owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.offer-slides.owl-theme:hover .owl-nav [class*=owl-] {
  left: -18px;
  opacity: 1;
  visibility: visible;
}

.offer-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -18px;
}

/*================================================
All Products Area CSS
=================================================*/
.all-products-area {
  padding-bottom: 30px;
}

.tab .tabs_item {
  display: none;
}

.tab .tabs_item:first-child {
  display: block;
}

.tab .tabs_item_best {
  display: none;
}

.tab .tabs_item_best:first-child {
  display: block;
}

.tab .tabs_item_trending {
  display: none;
}

.tab .tabs_item_trending:first-child {
  display: block;
}

.tab .tabs_item_popular {
  display: none;
}

.tab .tabs_item_popular:first-child {
  display: block;
}

.tab .tabs_item_special {
  display: none;
}

.tab .tabs_item_special:first-child {
  display: block;
}

.products-category-tab .tabs {
  list-style-type: none;
  margin-bottom: 40px;
  text-align: center;
  background-color: #fcfbfb;
  padding: 15px;
}

.products-category-tab .tabs li {
  display: inline-block;
  margin: 0 10px;
}

.products-category-tab .tabs li .tabs-nav-text,
.products-category-tab .tabs li a {
  display: inline-block;
  font-size: 18px;
  position: relative;
  padding-left: 18px;
  color: #858585;
  cursor: pointer;
}

.products-category-tab .tabs li .tabs-nav-text .dot,
.products-category-tab .tabs li a .dot {
  position: absolute;
  top: 7px;
  left: 0;
  width: 12px;
  height: 12px;
  border: 1px solid #858585;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.products-category-tab .tabs li .tabs-nav-text .dot::before,
.products-category-tab .tabs li a .dot::before {
  position: absolute;
  top: 0;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  background: #858585;
  margin: 1px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.products-category-tab .tabs li .tabs-nav-text:hover,
.products-category-tab .tabs li .tabs-nav-text:focus,
.products-category-tab .tabs li a:hover,
.products-category-tab .tabs li a:focus {
  color: #222222;
}

.products-category-tab .tabs li .tabs-nav-text:hover .dot,
.products-category-tab .tabs li .tabs-nav-text:focus .dot,
.products-category-tab .tabs li a:hover .dot,
.products-category-tab .tabs li a:focus .dot {
  border-color: #222222;
}

.products-category-tab .tabs li .tabs-nav-text:hover .dot::before,
.products-category-tab .tabs li .tabs-nav-text:focus .dot::before,
.products-category-tab .tabs li a:hover .dot::before,
.products-category-tab .tabs li a:focus .dot::before {
  background: #222222;
}

.products-category-tab .tabs li.current .tabs-nav-text,
.products-category-tab .tabs li.current a {
  color: #222222;
}

.products-category-tab .tabs li.current .tabs-nav-text .dot,
.products-category-tab .tabs li.current a .dot {
  border-color: #222222;
}

.products-category-tab .tabs li.current .tabs-nav-text .dot::before,
.products-category-tab .tabs li.current a .dot::before {
  background: #222222;
}

.products-category-tab .tabs.without-bg {
  padding: 0;
  background-color: transparent;
}

.single-product-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}

.single-product-box .product-image {
  position: relative;
}

.single-product-box .product-image img {
  width: 100%;

}

@media only screen and (max-width: 2000px) {
  .single-product-box .product-image img {
    max-height: 300px;
    min-height: 300px;
  }
}

@media only screen and (max-width: 700px) {
  .single-product-box .product-image img {
    max-height: 212px;
    min-height: 212px;
  }
}

.single-product-box .product-image img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.single-product-box .product-image ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  position: absolute;
  top: 10px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-product-box .product-image ul li {
  margin-bottom: 8px;
}

.single-product-box .product-image ul li a {
  width: 40px;
  height: 40px;
  line-height: 42px;
  background-color: #ffffff;
  color: #222222;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.single-product-box .product-image ul li a:hover,
.single-product-box .product-image ul li a:focus {
  color: #ffffff;
  background-color: #222222;
}

.single-product-box .product-image ul li a.disabled {
  cursor: not-allowed;
  background-color: #ffffff !important;
  color: #222222 !important;
  background: green !important;
  color: #fff !important;
}

.single-product-box .product-image ul li:last-child {
  margin-bottom: 0;
}

.single-product-box .product-image .sale-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ff2d2d;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  z-index: 2;
}

.single-product-box .product-image #timer {
  position: absolute;
  left: 0;
  text-align: center;
  right: 0;
  margin: 0 auto;
  bottom: 15px;
}

.single-product-box .product-image #timer div {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px 15px;
  color: #222222;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
}

.single-product-box .product-image #timer div span {
  display: block;
  font-size: 13px;
  font-weight: normal;
  color: #666666;
}

.single-product-box .product-content {
  margin-top: 20px;
}

.single-product-box .product-content h3 {
  font-size: 16px;
  margin-bottom: 0;
}

.single-product-box .product-content h3 a {
  color: #222222;
}

.single-product-box .product-content h3 a:hover,
.single-product-box .product-content h3 a:focus {
  color: #222222;
}

.single-product-box .product-content .product-price {
  margin-top: 10px;
  margin-bottom: 10px;
}

.single-product-box .product-content .product-price span {
  font-size: 18px;
  display: inline-block;
}

.single-product-box .product-content .product-price .old-price {
  margin-right: 5px;
  color: #858585;
  font-size: 15px;
  text-decoration: line-through;
}

.single-product-box .product-content .rating {
  margin-bottom: 12px;
  color: #eac11d;
}

.single-product-box .product-content .rating i {
  margin: 0 2px;
}

.single-product-box .product-content .btn {
  display: block;
  width: 100%;
}

.single-product-box:hover .product-image img:nth-child(2),
.single-product-box:focus .product-image img:nth-child(2) {
  opacity: 1;
  visibility: visible;
}

.single-product-box:hover .product-image ul,
.single-product-box:focus .product-image ul {
  right: 10px;
  opacity: 1;
  visibility: visible;
}

.single-product-box:hover .product-content .btn-light,
.single-product-box:focus .product-content .btn-light {
  background-color: #222222;
  color: #ffffff;
}

.all-products-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.all-products-slides.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: -5px;
  top: 0;
  height: 62.5%;
  width: 34px;
  background-color: #dedddc;
  font-size: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: 0;
  border-radius: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  color: #222222;
}

.all-products-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #222222;
  color: #ffffff;
}

.all-products-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -5px;
}

.all-products-slides.owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.all-products-slides.owl-theme:hover .owl-nav [class*=owl-] {
  left: -18px;
  opacity: 1;
  visibility: visible;
}

.all-products-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -18px;
}

/*================================================
Products QuickView Modal Area CSS
=================================================*/
.productQuickView .modal-dialog {
  max-width: 900px;
  margin: 0 auto;
}

.productQuickView .modal-content {
  border: none;
  padding: 40px;
  border-radius: 0;
}

.productQuickView .modal-content button.close {
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  opacity: 1;
  color: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  line-height: 35px;
  padding: 0;
  margin: 0;
  font-size: 20px;
  width: 35px;
  text-shadow: unset;
  height: 35px;
  border: none;
}

.productQuickView .modal-content button.close:hover {
  background-color: red;
  color: #ffffff;
}

.productQuickView .modal-content .productQuickView-image img {
  width: 100%;
}

.productQuickView .modal-content .product-content h3 {
  font-size: 22px;
  margin-bottom: 8px;
}

.productQuickView .modal-content .product-content h3 a {
  color: #222222;
}

.productQuickView .modal-content .product-content h3 a:hover {
  color: #222222;
}

.productQuickView .modal-content .product-content .price {
  margin-bottom: 5px;
}

.productQuickView .modal-content .product-content .price span {
  display: inline-block;
  font-size: 18px;
  color: #222222;
}

.productQuickView .modal-content .product-content .product-review .rating {
  display: inline-block;
  padding-right: 10px;
}

.productQuickView .modal-content .product-content .product-review .rating i {
  color: #ffba0a;
  margin: 0 2px;
}

.productQuickView .modal-content .product-content .product-review .rating-count {
  display: inline-block;
  color: #222222;
  border-bottom: 1px solid #222222;
  line-height: initial;
}

.productQuickView .modal-content .product-content .product-info {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.productQuickView .modal-content .product-content .product-info li {
  font-size: 16px;
  color: #222222;
  margin-bottom: 8px;
}

.productQuickView .modal-content .product-content .product-info li:last-child {
  margin-bottom: 0;
}

.productQuickView .modal-content .product-content .product-info li span {
  color: #666666;
}

.productQuickView .modal-content .product-content .product-info li a {
  display: inline-block;
  font-weight: 500;
  color: #222222;
}

.productQuickView .modal-content .product-content .product-info li a:hover {
  color: #222222;
}

.productQuickView .modal-content .product-content .product-color-switch {
  margin-top: 8px;
}

.productQuickView .modal-content .product-content .product-color-switch h4 {
  font-size: 16px;
  color: #666666;
  margin-bottom: 6px;
}

.productQuickView .modal-content .product-content .product-color-switch ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -4px;
  margin-left: -4px;
  padding-left: 0;
}

.productQuickView .modal-content .product-content .product-color-switch ul li {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a {
  display: inline-block;
  position: relative;
  border: 1px solid transparent;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a::before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a:hover,
.productQuickView .modal-content .product-content .product-color-switch ul li a:focus {
  border-color: #222222;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-white::before {
  background: #ffffff;
  border: 1px solid #eeeeee;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-black::before {
  background: #222222;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-red::before {
  background: red;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-blue::before {
  background: blue;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-green::before {
  background: green;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-yellow::before {
  background: yellow;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-yellowgreen::before {
  background: yellowgreen;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-pink::before {
  background: pink;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-violet::before {
  background: violet;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-teal::before {
  background: teal;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-plum::before {
  background: plum;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-lime::before {
  background: lime;
}

.productQuickView .modal-content .product-content .product-color-switch ul li a.color-blueviolet::before {
  background: blueviolet;
}

.productQuickView .modal-content .product-content .product-color-switch ul li.active a {
  border-color: #222222;
}

.productQuickView .modal-content .product-content .product-size-wrapper {
  margin-top: 8px;
  margin-bottom: 25px;
}

.productQuickView .modal-content .product-content .product-size-wrapper h4 {
  font-size: 16px;
  color: #666666;
  margin-bottom: 8px;
}

.productQuickView .modal-content .product-content .product-size-wrapper ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -8px;
  margin-left: -8px;
  padding-left: 0;
}

.productQuickView .modal-content .product-content .product-size-wrapper ul li {
  display: inline-block;
  margin-left: 8px;
  margin-top: 8px;
}

.productQuickView .modal-content .product-content .product-size-wrapper ul li a {
  width: 40px;
  height: 35px;
  display: inline-block;
  line-height: 35px;
  border: 1px solid #eeeeee;
  color: #858585;
  text-align: center;
}

.productQuickView .modal-content .product-content .product-size-wrapper ul li a:hover,
.productQuickView .modal-content .product-content .product-size-wrapper ul li a:focus {
  border-color: #222222;
  color: #222222;
}

.productQuickView .modal-content .product-content .product-size-wrapper ul li.active a {
  border-color: #222222;
  color: #222222;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 50px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter span.minus-btn {
  left: 0;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter span.plus-btn {
  right: 0;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter span:hover {
  color: #222222;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter input {
  height: 45px;
  color: #222222;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: #222222;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter input:-ms-input-placeholder {
  color: #222222;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter input::-ms-input-placeholder {
  color: #222222;
}

.productQuickView .modal-content .product-content .product-add-to-cart .input-counter input::placeholder {
  color: #222222;
}

.productQuickView .modal-content .product-content .product-add-to-cart .btn {
  height: 45px;
  top: -2px;
  position: relative;
}

.productQuickView .modal-content .product-content .product-add-to-cart .btn i {
  margin-right: 2px;
}

.productQuickView .modal-content .product-content .view-full-info {
  margin-top: 25px;
  color: #222222;
  line-height: initial;
  display: inline-block;
  position: relative;
  font-weight: 600;
  font-size: 15px;
}

.productQuickView .modal-content .product-content .view-full-info::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.productQuickView .modal-content .product-content .view-full-info:hover::before {
  width: 100%;
}

/*================================================
Category Products Area CSS
=================================================*/
.category-products-area {
  padding-bottom: 30px;
}

.category-products-area.pt-60 {
  padding-bottom: 0;
  margin-bottom: -30px;
}

.single-category-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  overflow: hidden;
}

.single-category-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: .7;
  z-index: 2;
}

.single-category-box img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.single-category-box .category-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;
}

.single-category-box .category-content h3 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}

.single-category-box .category-content .btn {
  margin-top: 22px;
}

.single-category-box .category-content .btn-light {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}

.single-category-box .category-content .btn-light:not(:disabled):not(.disabled).active,
.single-category-box .category-content .btn-light:not(:disabled):not(.disabled):active,
.show>.single-category-box .category-content .btn-light.dropdown-toggle {
  color: #222222;
  background-color: #ffffff;
  border-color: #ffffff;
}

.single-category-box .category-content .btn-light:hover,
.single-category-box .category-content .btn-light:focus {
  color: #222222;
  background-color: #ffffff;
  border-color: #ffffff;
}

.single-category-box .link-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.single-category-box:hover img,
.single-category-box:focus img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*================================================
Trending Products Area CSS
=================================================*/
.trending-products-area {
  padding-bottom: 30px;
}

.trending-products-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.trending-products-slides.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: -5px;
  top: 0;
  height: 62.5%;
  width: 34px;
  background-color: #dedddc;
  font-size: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: 0;
  border-radius: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  color: #222222;
}

.trending-products-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #222222;
  color: #ffffff;
}

.trending-products-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -5px;
}

.trending-products-slides.owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.trending-products-slides.owl-theme:hover .owl-nav [class*=owl-] {
  left: -18px;
  opacity: 1;
  visibility: visible;
}

.trending-products-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -18px;
}

.trending-products-slides-two.owl-theme .owl-nav.disabled+.owl-dots {
  line-height: initial;
  margin-bottom: 30px;
  margin-top: 10px;
}

/*================================================
Best Sellers Area CSS
=================================================*/
.best-sellers-area {
  padding-bottom: 30px;
}

.best-sellers-products-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.best-sellers-products-slides.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: -5px;
  top: 0;
  height: 62.5%;
  width: 34px;
  background-color: #dedddc;
  font-size: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: 0;
  border-radius: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  color: #222222;
}

.best-sellers-products-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #222222;
  color: #ffffff;
}

.best-sellers-products-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -5px;
}

.best-sellers-products-slides.owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.best-sellers-products-slides.owl-theme:hover .owl-nav [class*=owl-] {
  left: -18px;
  opacity: 1;
  visibility: visible;
}

.best-sellers-products-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -18px;
}

.best-sellers-products-slides-two.owl-theme .owl-nav.disabled+.owl-dots {
  line-height: initial;
  margin-bottom: 30px;
  margin-top: 10px;
}

/*================================================
Facility Area CSS
=================================================*/
.facility-area {
  background-color: #f5f5f5;
  padding-top: 35px;
  padding-bottom: 35px;
}

.facility-area.black-bg {
  background-color: #222222;
}

.facility-area.black-bg .facility-box h3 {
  color: #ffffff;
}

.facility-box {
  text-align: center;
}

.facility-box .icon {
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 25px;
  color: #222222;
  line-height: 80px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.facility-box .icon::before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  margin: 6px;
  border-radius: 50%;
}

.facility-box .icon i {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.facility-box h3 {
  margin-bottom: 0;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 15px;
}

.facility-box:hover .icon i,
.facility-box:focus .icon i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-area {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/testimonials-bg.jpg);
}

.testimonials-area::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #222222;
  opacity: .70;
}

.testimonials-area.bg2 {
  background-image: url(../images/testimonials-bg2.jpg);
  background-attachment: fixed;
}

.single-testimonials {
  text-align: center;
  margin: 0 auto;
  max-width: 745px;
}

.single-testimonials .client-image {
  margin-bottom: 30px;
}

.single-testimonials .client-image img {
  width: 65px !important;
  border-radius: 50%;
  height: 65px;
  display: inline-block !important;
}

.single-testimonials p {
  color: #ffffff;
  margin-bottom: 0;
  font-style: italic;
  line-height: 1.9;
}

.single-testimonials .client-info {
  margin-top: 25px;
}

.single-testimonials .client-info h4 {
  font-size: 18px;
  color: #ffffff;
}

.single-testimonials .client-info span {
  display: block;
  color: #ffffff;
  font-size: 13px;
  opacity: .95;
}

.testimonials-slides.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 30px;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot {
  outline: 0;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot span {
  background: transparent !important;
  border: 1px solid #ffffff;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot:hover span,
.testimonials-slides.owl-theme .owl-dots .owl-dot.active span {
  background: #ffffff !important;
}

/*================================================
Products Offer Area CSS
=================================================*/
.products-offer-area {
  position: relative;
  z-index: 1;
  background-image: url(../images/offer-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.products-offer-area.bg-image2 {
  background-image: url(../images/offer-bg2.jpg);
}

.products-offer-content {
  max-width: 550px;
  border: 1px solid #ffffff;
  text-align: center;
  padding: 70px 40px;
  position: relative;
  z-index: 1;
}

.products-offer-content::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffffff;
  z-index: -1;
  margin: 10px;
}

.products-offer-content span {
  font-size: 16px;
  display: block;
}

.products-offer-content h1 {
  font-size: 55px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 5px;
}

.products-offer-content p {
  line-height: initial;
  margin-bottom: 25px;
  font-size: 18px;
  color: #222222;
}

/*================================================
News Area CSS
=================================================*/
.single-news-post {
  margin-bottom: 30px;
}

.single-news-post .news-content {
  position: relative;
  background: rgba(245, 245, 245, 0.95);
  z-index: 1;
  padding: 30px;
}

.single-news-post .news-content h3 {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 22px;
  font-weight: 700;
}

.single-news-post .news-content h3 a {
  color: #222222;
}

.single-news-post .news-content h3 a:hover,
.single-news-post .news-content h3 a:focus {
  color: #222222;
}

.single-news-post .news-content .author {
  display: block;
  color: #666666;
  margin-top: 12px;
  margin-bottom: 15px;
}

.single-news-post .news-content .author a {
  display: inline-block;
  color: #222222;
}

.single-news-post .news-content .author a:hover,
.single-news-post .news-content .author a:focus {
  color: #222222;
}

.single-news-post .news-content p {
  margin-bottom: 20px;
}

.single-blog-post {
  margin-bottom: 30px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);
}

.single-blog-post .blog-image {
  position: relative;
  overflow: hidden;
}

.single-blog-post .blog-image img {
  width: 100%;
}

.single-blog-post .blog-image a {
  display: block;
}

.single-blog-post .blog-image a img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-blog-post .blog-image .post-tag {
  position: absolute;
  left: 0;
  top: 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-blog-post .blog-image .post-tag a {
  background: #222222;
  color: #ffffff;
  padding: 7px 20px;
}

.single-blog-post .blog-post-content {
  padding: 25px;
}

.single-blog-post .blog-post-content .date {
  color: #666666;
  display: block;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 13px;
}

.single-blog-post .blog-post-content h3 {
  margin-top: 10px;
  margin-bottom: 12px;
  line-height: 27px;
  font-size: 18px;
  font-weight: 600;
}

.single-blog-post .blog-post-content h3 a {
  color: #222222;
}

.single-blog-post .blog-post-content h3 a:hover,
.single-blog-post .blog-post-content h3 a:focus {
  color: #222222;
}

.single-blog-post .blog-post-content p {
  margin-bottom: 15px;
}

.single-blog-post .read-more-btn {
  position: relative;
  font-weight: 500;
  color: #666666;
}

.single-blog-post .read-more-btn:hover {
  color: #222222;
  letter-spacing: 1px;
}

.single-blog-post:hover .blog-image a img,
.single-blog-post:focus .blog-image a img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.single-blog-post:hover .blog-image .post-tag,
.single-blog-post:focus .blog-image .post-tag {
  top: 50px;
}

.single-blog-post:hover .blog-image .post-tag a,
.single-blog-post:focus .blog-image .post-tag a {
  background: #222222;
  color: #ffffff;
}

/*================================================
News Details Area CSS
=================================================*/
.blog-details .article-img img {
  width: 100%;
}

.blog-details .article-header {
  border: 1px solid #eeeeee;
  border-bottom: none;
  padding: 30px;
}

.blog-details .article-header .entry-meta {
  padding: 0;
  margin: 0 0 17px;
  list-style-type: none;
}

.blog-details .article-header .entry-meta li {
  display: inline-block;
  color: #777777;
  margin-right: 15px;
  font-size: 14px;
}

.blog-details .article-header .entry-meta li i {
  color: #222222;
  margin-right: 3px;
}

.blog-details .article-header .entry-meta li:last-child {
  margin-right: 0;
}

.blog-details .article-header .entry-meta li a {
  display: inline-block;
  color: #666666;
}

.blog-details .article-header .entry-meta li a:hover {
  color: #222222;
}

.blog-details .article-header h3 {
  margin-bottom: 0;
  font-size: 19px;
}

.blog-details .article-content {
  padding: 30px 30px 35px;
  border: 1px solid #eeeeee;
  border-top: none;
}

.blog-details .article-content .entry-meta {
  padding: 0;
  margin: 0 0 17px;
  list-style-type: none;
}

.blog-details .article-content .entry-meta li {
  display: inline-block;
  color: #777777;
  margin-right: 15px;
  font-size: 14px;
}

.blog-details .article-content .entry-meta li i {
  color: #222222;
  margin-right: 3px;
}

.blog-details .article-content .entry-meta li:last-child {
  margin-right: 0;
}

.blog-details .article-content .entry-meta li a {
  display: inline-block;
  color: #666666;
}

.blog-details .article-content .entry-meta li a:hover {
  color: #222222;
}

.blog-details .article-content .category {
  padding: 0;
  margin: 30px 0 0;
  list-style-type: none;
}

.blog-details .article-content .category li {
  display: inline-block;
  margin-right: 4px;
}

.blog-details .article-content .category li span {
  font-weight: 600;
}

.blog-details .article-content .category li a {
  border: 1px solid #222222;
  border-radius: 5px;
  padding: 4px 15px;
  font-size: 13px;
  color: #222222;
  position: relative;
  z-index: 1;
}

.blog-details .article-content .category li a::before {
  position: absolute;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #222222;
  z-index: -1;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.blog-details .article-content .category li a:hover,
.blog-details .article-content .category li a:focus {
  color: #ffffff;
}

.blog-details .article-content .category li a:hover::before,
.blog-details .article-content .category li a:focus::before {
  opacity: 1;
  visibility: visible;
}

.blog-details .article-content h3 {
  margin-bottom: 17px;
  font-size: 19px;
}

blockquote {
  background-color: #fafafa;
  padding: 30px 30px 30px 72px !important;
  line-height: 25px;
  font-weight: 500;
  position: relative;
  margin: 20px 0;
}

blockquote::before {
  font-family: "Font Awesome 5 Free";
  content: "\f10d";
  position: absolute;
  left: 30px;
  top: 35px;
  color: #222222;
  font-size: 25px;
  font-weight: 900;
  font-style: normal;
}

blockquote p {
  font-weight: 600;
}

blockquote cite {
  position: relative;
  padding-left: 17px;
  font-weight: 700;
}

blockquote cite::before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #222222;
  left: 0;
  width: 10px;
  height: 2px;
}

.post-controls-buttons {
  overflow: hidden;
  padding: 30px;
  border: 1px solid #eeeeee;
  margin-top: 30px;
  margin-bottom: 30px;
}

.post-controls-buttons div:first-child {
  float: left;
}

.post-controls-buttons div:last-child {
  float: right;
}

.post-controls-buttons div a {
  display: inline-block;
  border: 1px solid #eeeeee;
  padding: 11px 20px 9px;
  color: #222222;
  text-transform: uppercase;
  border-radius: 30px;
  font-weight: 600;
  font-size: 13px;
}

.post-controls-buttons div a:hover,
.post-controls-buttons div a:focus {
  border-color: #222222;
  color: #ffffff;
  background-color: #222222;
}

.comments-area .comments-title {
  font-size: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.comments-area ol,
.comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .children {
  margin-left: 25px;
}

.comments-area .comment-body {
  border-bottom: 1px dashed #eeeeee;
  margin-left: 65px;
  color: #222222;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.comments-area .comment-body .reply {
  margin-top: 15px;
}

.comments-area .comment-body .reply a {
  border: 1px solid #222222;
  color: #222222;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #222222;
}

.comments-area .comment-meta {
  margin-bottom: .8em;
}

.comments-area .comment-author {
  font-size: 15px;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}

.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}

.comments-area .comment-author .fn {
  font-weight: 600;
}

.comments-area .comment-author .says {
  display: none;
}

.comments-area .comment-metadata {
  color: #666666;
  letter-spacing: 0.10em;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}

.comments-area .comment-metadata a {
  color: #666666;
}

.comments-area .comment-metadata a:hover {
  color: #222222;
}

.comments-area .comment-content p {
  font-size: 14px;
}

.comments-area .comment-respond {
  background: #fafafa;
  padding: 30px 25px;
  overflow: hidden;
  border: 1px solid #eeeeee;
}

.comments-area .comment-respond .comment-reply-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
}

.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  font-size: 15px;
  display: inline-block;
}

.comments-area .comment-respond .comment-notes {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 10px;
}

.comments-area .comment-respond .comment-notes .required {
  color: red;
}

.comments-area .comment-respond .comment-form-comment {
  margin-top: 15px;
  float: left;
  width: 100%;
}

.comments-area .comment-respond label {
  display: block;
  margin-bottom: 10px;
  color: #666666;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 600;
}

.comments-area .comment-respond input[type="date"],
.comments-area .comment-respond input[type="time"],
.comments-area .comment-respond input[type="datetime-local"],
.comments-area .comment-respond input[type="week"],
.comments-area .comment-respond input[type="month"],
.comments-area .comment-respond input[type="text"],
.comments-area .comment-respond input[type="email"],
.comments-area .comment-respond input[type="url"],
.comments-area .comment-respond input[type="password"],
.comments-area .comment-respond input[type="search"],
.comments-area .comment-respond input[type="tel"],
.comments-area .comment-respond input[type="number"],
.comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.comments-area .comment-respond input[type="date"]:focus,
.comments-area .comment-respond input[type="time"]:focus,
.comments-area .comment-respond input[type="datetime-local"]:focus,
.comments-area .comment-respond input[type="week"]:focus,
.comments-area .comment-respond input[type="month"]:focus,
.comments-area .comment-respond input[type="text"]:focus,
.comments-area .comment-respond input[type="email"]:focus,
.comments-area .comment-respond input[type="url"]:focus,
.comments-area .comment-respond input[type="password"]:focus,
.comments-area .comment-respond input[type="search"]:focus,
.comments-area .comment-respond input[type="tel"]:focus,
.comments-area .comment-respond input[type="number"]:focus,
.comments-area .comment-respond textarea:focus {
  border-color: #222222;
}

.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 7px;
}

.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #666666;
  font-weight: normal;
  text-transform: initial;
}

.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}

.comments-area .comment-respond .form-submit input {
  background: #222222;
  border: none;
  color: #ffffff;
  padding: 10.5px 25px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-weight: 600;
  font-size: 14px;
}

.comments-area .comment-respond .form-submit input:hover,
.comments-area .comment-respond .form-submit input:focus {
  background-color: #222222;
  color: #ffffff;
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  border-top: 1px solid #f5f5f5;
}

.newsletter-content h3 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.newsletter-form {
  position: relative;
}

.newsletter-form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 45px;
  border: none;
  background-color: #222222;
  color: #ffffff;
  outline: 0;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0 20px;
}

.newsletter-form button:hover,
.newsletter-form button:focus {
  background-color: #000000;
}

.newsletter-form .validation-danger {
  color: red;
  margin-top: 10px;
}

.newsletter-form .validation-success {
  margin-top: 10px;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  background-color: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 30px;
}

.partner-item {
  text-align: center;
}

.partner-item a img {
  width: auto !important;
  display: inline-block !important;
}

/*================================================
Instagram Area CSS
=================================================*/
.instagram-box {
  text-align: center;
  position: relative;
}

.instagram-box::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}

.instagram-box .icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 30px;
  margin-top: 20px;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  z-index: 2;
}

.instagram-box a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
}

.instagram-box:hover::before,
.instagram-box:focus::before {
  visibility: visible;
  opacity: .65;
}

.instagram-box:hover .icon,
.instagram-box:focus .icon {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  background-color: #f8f8f8;
  padding-top: 12px;
  padding-bottom: 12px;
}

.page-title-area ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.page-title-area ul li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  color: #666666;
  font-size: 13px;
}

.page-title-area ul li a {
  color: #666666;
  font-size: 13px;
}

.page-title-area ul li a:hover,
.page-title-area ul li a:focus {
  color: #222222;
}

.page-title-area ul li::before {
  content: '/';
  position: absolute;
  display: inline-block;
  left: -10px;
  top: 0;
}

.page-title-area ul li:first-child::before {
  display: none;
}

.page-title-area ul li:last-child {
  margin-right: 0;
}

/*================================================
Products Collections Area CSS
=================================================*/
.products-collections-area {
  padding-bottom: 30px;
}

.products-collections-area .container-fluid {
  width: 90%;
}

.collections-box {
  overflow: hidden;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.collections-box img {
  width: 100%;
}

.collections-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
  background-color: #ffffff;
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 2;
}

.collections-box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%;
  background-color: #ffffff;
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 2;
}

.collections-box .category {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 0;
  bottom: 25px;
  border: 1px solid #ececec;
  padding: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.collections-box .category::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ededed;
  opacity: 0.94;
  z-index: 1;
  margin: 6px;
}

.collections-box .category h4 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 7px;
  position: relative;
  z-index: 1;
}

.collections-box .category span {
  color: #666666;
  position: relative;
  display: block;
  z-index: 1;
}

.collections-box img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.collections-box a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.collections-box:hover img,
.collections-box:focus img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.collections-box:hover::after,
.collections-box:focus::after {
  left: 100%;
}

.collections-box:hover::before,
.collections-box:focus::before {
  left: -100%;
}

.single-collections-box {
  overflow: hidden;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.single-collections-box img {
  width: 100%;
}

.single-collections-box .category {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.single-collections-box .category h4 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 7px;
  position: relative;
  z-index: 1;
}

.single-collections-box .category span {
  color: #666666;
  position: relative;
  display: block;
  z-index: 1;
}

.single-collections-box img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-collections-box a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.single-collections-box:hover img,
.single-collections-box:focus img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.single-collections-box:hover .category,
.single-collections-box:focus .category {
  bottom: 10px;
}

.products-filter-options {
  margin-bottom: 30px;
}

.products-filter-options span {
  color: #222222;
  display: inline-block;
  padding-right: 5px;
}

.products-filter-options span a {
  text-transform: uppercase;
  color: #222222;
  font-weight: 700;
  margin-right: 20px;
}

.products-filter-options span a i {
  font-size: 17px;
}

.products-filter-options .view-list-row {
  position: relative;
  top: 2.4px;
}

.products-filter-options .view-list-row .view-column a {
  display: inline-block;
  margin-right: 3px;
}

.products-filter-options .view-list-row .view-column .icon-view-two span {
  cursor: pointer;
  width: 7px;
  height: 14px;
  display: block;
  float: left;
  border: 1px solid #d0d0d0;
  -webkit-transition: border .2s;
  transition: border .2s;
}

.products-filter-options .view-list-row .view-column .icon-view-two span:not(:first-child) {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .icon-view-two.active span,
.products-filter-options .view-list-row .view-column .icon-view-two:hover span {
  border-color: #222222;
}

.products-filter-options .view-list-row .view-column .icon-view-three {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .icon-view-three span {
  width: 7px;
  height: 14px;
  display: block;
  float: left;
  border: 1px solid #d0d0d0;
  -webkit-transition: border .2s;
  transition: border .2s;
}

.products-filter-options .view-list-row .view-column .icon-view-three span:not(:first-child) {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .icon-view-three.active span,
.products-filter-options .view-list-row .view-column .icon-view-three:hover span {
  border-color: #222222;
}

.products-filter-options .view-list-row .view-column .icon-view-four {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .icon-view-four span {
  width: 7px;
  height: 14px;
  display: block;
  float: left;
  border: 1px solid #d0d0d0;
  -webkit-transition: border .2s;
  transition: border .2s;
}

.products-filter-options .view-list-row .view-column .icon-view-four span:not(:first-child) {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .icon-view-four.active span,
.products-filter-options .view-list-row .view-column .icon-view-four:hover span {
  border-color: #222222;
}

.products-filter-options .view-list-row .view-column .icon-view-five {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .icon-view-five span {
  width: 5px;
  height: 14px;
  display: block;
  float: left;
  border: 1px solid #d0d0d0;
  -webkit-transition: border .2s;
  transition: border .2s;
}

.products-filter-options .view-list-row .view-column .icon-view-five span:not(:first-child) {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .icon-view-five.active span,
.products-filter-options .view-list-row .view-column .icon-view-five:hover span {
  border-color: #222222;
}

.products-filter-options .view-list-row .view-column .icon-view-six {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .icon-view-six span {
  width: 3px;
  height: 14px;
  display: block;
  float: left;
  border: 1px solid #d0d0d0;
  -webkit-transition: border .2s;
  transition: border .2s;
}

.products-filter-options .view-list-row .view-column .icon-view-six span:not(:first-child) {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .icon-view-six.active span,
.products-filter-options .view-list-row .view-column .icon-view-six:hover span {
  border-color: #222222;
}

.products-filter-options .view-list-row .view-column .view-grid-switch {
  margin-left: 1px;
}

.products-filter-options .view-list-row .view-column .view-grid-switch span {
  width: 24px;
  height: 6px;
  display: block;
  position: relative;
  background-color: #d0d0d0;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
}

.products-filter-options .view-list-row .view-column .view-grid-switch span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 6px;
  background-color: #ffffff;
  display: block;
  width: 2px;
  height: 6px;
}

.products-filter-options .view-list-row .view-column .view-grid-switch span:not(:first-child) {
  margin-top: 2px;
}

.products-filter-options .view-list-row .view-column .view-grid-switch.active span,
.products-filter-options .view-list-row .view-column .view-grid-switch:hover span {
  background-color: #222222;
}

.products-filter-options p {
  text-align: center;
  font-size: 13px;
}

.products-filter-options .show-products-number select {
  background: transparent;
  outline: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

.products-filter-options .products-ordering-list select {
  background: transparent;
  outline: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

.products-filter-options .col:last-child {
  -ms-flex-pack: end;
  -moz-justify-content: flex-end;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.products-collections-listing.products-col-two .products-col-item {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}

.products-collections-listing.products-col-two .single-product-box .product-image a img {
  width: 100%;
}

.products-collections-listing.products-col-three .products-col-item {
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.products-collections-listing.products-col-three .single-product-box .product-image a img {
  width: 100%;
}

.products-collections-listing.products-col-four .products-col-item {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
}

.products-collections-listing.products-row-view .products-col-item {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}

.products-collections-listing.products-row-view .col-lg-3 {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}

.products-collections-listing.products-row-view .col-lg-2 {
  -ms-flex: 0 0 33.3333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.products-collections-listing.products-row-view .single-product-box {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  background-color: #fdfdfd;
}

.products-collections-listing.products-row-view .single-product-box .product-content {
  text-align: left;
  padding-left: 25px;
}

.products-collections-listing .col-lg-2 .single-product-box .product-image a img {
  width: 100%;
}

/*================================================
Woocommerce Sidebar Area CSS
=================================================*/
.woocommerce-sidebar-area .collapse-widget {
  margin-bottom: 30px;
}

.woocommerce-sidebar-area .collapse-widget:last-child {
  margin-bottom: 0;
}

.woocommerce-sidebar-area .collapse-widget .collapse-widget-title {
  background-color: #f5f5f5;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  padding: 15px;
}

.woocommerce-sidebar-area .collapse-widget .collapse-widget-title::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 35px;
  height: 2px;
  background: #eeeeee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.woocommerce-sidebar-area .collapse-widget .collapse-widget-title i {
  position: absolute;
  right: 15px;
  font-size: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #858585;
}

.woocommerce-sidebar-area .collapse-widget .collapse-widget-title:hover::before,
.woocommerce-sidebar-area .collapse-widget .collapse-widget-title:focus::before {
  width: 40px;
}

.woocommerce-sidebar-area .collapse-widget .collapse-widget-title.active i {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.woocommerce-sidebar-area .collapse-widget .aside-widget-title {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 7px;
}

.woocommerce-sidebar-area .collapse-widget .aside-widget-title::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 35px;
  height: 2px;
  background: #eeeeee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.woocommerce-sidebar-area .collapse-widget .aside-widget-title i {
  position: relative;
  left: 2px;
  font-size: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #858585;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -10px;
  margin-left: -10px;
  padding-left: 0;
  padding-top: 20px;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list ul li {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list ul li a {
  font-size: 12px;
  display: inline-block;
  border: 1px solid #eeeeee;
  padding: 4px 26px 4px 12px;
  color: #858585;
  position: relative;
  text-align: center;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list ul li a:hover,
.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list ul li a:focus {
  border-color: #222222;
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list ul li a:hover::before,
.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list ul li a:focus::before {
  color: red;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list ul li a::before {
  content: 'x';
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list ul li.active a {
  border-color: #222222;
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list .delete-selected-filters {
  margin-top: 12px;
  cursor: pointer;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list .delete-selected-filters a {
  color: #666666;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list .delete-selected-filters a i {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list .delete-selected-filters a span {
  display: inline-block;
  font-size: 12px;
  color: #666666;
  text-decoration: underline;
}

.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list .delete-selected-filters a:hover i,
.woocommerce-sidebar-area .collapse-widget .selected-filters-wrap-list .delete-selected-filters a:focus i {
  color: red;
}

.woocommerce-sidebar-area .collapse-widget .collections-list-row {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 20px;
}

.woocommerce-sidebar-area .collapse-widget .collections-list-row li {
  margin-bottom: 12px;
}

.woocommerce-sidebar-area .collapse-widget .collections-list-row li a {
  color: #858585;
}

.woocommerce-sidebar-area .collapse-widget .collections-list-row li a:hover,
.woocommerce-sidebar-area .collapse-widget .collections-list-row li a:focus {
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .collections-list-row li.active a {
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .collections-list-row li:last-child {
  margin-bottom: 0;
}

.woocommerce-sidebar-area .collapse-widget .brands-list-row {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 20px;
}

.woocommerce-sidebar-area .collapse-widget .brands-list-row li {
  margin-bottom: 12px;
}

.woocommerce-sidebar-area .collapse-widget .brands-list-row li a {
  color: #858585;
}

.woocommerce-sidebar-area .collapse-widget .brands-list-row li a:hover,
.woocommerce-sidebar-area .collapse-widget .brands-list-row li a:focus {
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .brands-list-row li.active a {
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .brands-list-row li:last-child {
  margin-bottom: 0;
}

.woocommerce-sidebar-area .collapse-widget .size-list-row {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -10px;
  margin-left: -10px;
  padding-left: 0;
  padding-top: 20px;
}

.woocommerce-sidebar-area .collapse-widget .size-list-row li {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
}

.woocommerce-sidebar-area .collapse-widget .size-list-row li a {
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 35px;
  border: 1px solid #eeeeee;
  color: #858585;
  text-align: center;
}

.woocommerce-sidebar-area .collapse-widget .size-list-row li a:hover,
.woocommerce-sidebar-area .collapse-widget .size-list-row li a:focus {
  border-color: #222222;
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .size-list-row li.active a {
  border-color: #222222;
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .price-list-row {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 20px;
}

.woocommerce-sidebar-area .collapse-widget .price-list-row li {
  margin-bottom: 10px;
}

.woocommerce-sidebar-area .collapse-widget .price-list-row li a {
  color: #858585;
}

.woocommerce-sidebar-area .collapse-widget .price-list-row li a:hover,
.woocommerce-sidebar-area .collapse-widget .price-list-row li a:focus {
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .price-list-row li.active a {
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .price-list-row li:last-child {
  margin-bottom: 0;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -4px;
  margin-left: -4px;
  padding-left: 0;
  padding-top: 20px;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a {
  display: inline-block;
  position: relative;
  border: 1px solid transparent;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a::before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a:hover,
.woocommerce-sidebar-area .collapse-widget .color-list-row li a:focus {
  border-color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-white::before {
  background: #ffffff;
  border: 1px solid #eeeeee;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-black::before {
  background: #222222;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-red::before {
  background: red;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-blue::before {
  background: blue;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-green::before {
  background: green;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-yellow::before {
  background: yellow;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-yellowgreen::before {
  background: yellowgreen;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-pink::before {
  background: pink;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-violet::before {
  background: violet;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-teal::before {
  background: teal;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-plum::before {
  background: plum;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-lime::before {
  background: lime;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li a.color-blueviolet::before {
  background: blueviolet;
}

.woocommerce-sidebar-area .collapse-widget .color-list-row li.active a {
  border-color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .tags-list-row {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -10px;
  margin-left: -10px;
  padding-left: 0;
  padding-top: 20px;
}

.woocommerce-sidebar-area .collapse-widget .tags-list-row li {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
}

.woocommerce-sidebar-area .collapse-widget .tags-list-row li a {
  display: inline-block;
  border: 1px solid #eeeeee;
  padding: 5px 15px;
  color: #858585;
  text-align: center;
}

.woocommerce-sidebar-area .collapse-widget .tags-list-row li a:hover,
.woocommerce-sidebar-area .collapse-widget .tags-list-row li a:focus {
  border-color: #222222;
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .tags-list-row li.active a {
  border-color: #222222;
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-image {
  overflow: hidden;
  width: 100px;
  margin-right: 15px;
  position: relative;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-image a img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-content span a {
  color: #666666;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-content span a:hover {
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-content h3 {
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 6px;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-content h3 a {
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-content h3 a:hover,
.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-content h3 a:focus {
  color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-content .product-price {
  margin-top: 5px;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-content .product-price span {
  font-size: 17px;
  display: inline-block;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products .products-content .product-price .old-price {
  color: #858585;
  font-size: 15px;
  text-decoration: line-through;
}

.woocommerce-sidebar-area .collapse-widget .aside-single-products:hover .products-image a img,
.woocommerce-sidebar-area .collapse-widget .aside-single-products:focus .products-image a img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.woocommerce-sidebar-area .collapse-widget .aside-trending-products {
  overflow: hidden;
  position: relative;
  text-align: center;
  margin-top: 25px;
}

.woocommerce-sidebar-area .collapse-widget .aside-trending-products .category {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.woocommerce-sidebar-area .collapse-widget .aside-trending-products .category h4 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 7px;
  position: relative;
  z-index: 1;
}

.woocommerce-sidebar-area .collapse-widget .aside-trending-products .category span {
  color: #666666;
  position: relative;
  display: block;
  z-index: 1;
}

.woocommerce-sidebar-area .collapse-widget .aside-trending-products img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.woocommerce-sidebar-area .collapse-widget .aside-trending-products a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.woocommerce-sidebar-area .collapse-widget .aside-trending-products:hover img,
.woocommerce-sidebar-area .collapse-widget .aside-trending-products:focus img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.woocommerce-sidebar-area .collapse-widget .aside-trending-products:hover .category,
.woocommerce-sidebar-area .collapse-widget .aside-trending-products:focus .category {
  bottom: 10px;
}

.woocommerce-sidebar-area .collapse-widget .aside-trending-products:first-child {
  margin-top: 0;
}

.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price {
  margin-top: 20px;
}

.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs-handle {
  cursor: pointer;
}

.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-from,
.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-to,
.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-single {
  background-color: #222222;
  padding: 1px 5px 1px 11.5px;
}

.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-from::before,
.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-to::before,
.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-single::before {
  border-top-color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-from::after,
.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-to::after,
.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-single::after {
  content: '$';
  position: absolute;
  left: 5px;
  top: 0.5px;
}

.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-bar {
  background-color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-handle>i:first-child {
  background-color: #222222;
}

.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-min,
.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-max {
  padding: 1px 3px 1px 9.5px;
}

.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-min::before,
.woocommerce-sidebar-area .collapse-widget .collection-filter-by-price .irs--flat .irs-max::before {
  content: '$';
  position: absolute;
  left: 3px;
  top: 0.5px;
}

/*================================================
Products Filter Modal Area CSS
=================================================*/
.productsFilterModal.modal.left .modal-dialog {
  position: fixed;
  margin: auto;
  width: 400px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.productsFilterModal.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.productsFilterModal.modal.left.fade.show .modal-dialog {
  left: 0;
}

.productsFilterModal .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none;
  position: relative;
  padding-top: 60px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.productsFilterModal .modal-content .modal-body {
  padding: 0;
}

.productsFilterModal button.close {
  position: absolute;
  left: 20px;
  top: 20px;
  outline: 0;
  border: 1px solid #eee;
  text-shadow: unset;
  color: #666666;
  opacity: 1;
  line-height: initial;
  font-size: 14px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 5px 10px;
}

.productsFilterModal button.close i {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.productsFilterModal button.close:hover,
.productsFilterModal button.close:focus {
  color: #222222;
}

.productsFilterModal button.close:hover i,
.productsFilterModal button.close:focus i {
  color: red;
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
  position: relative;
  text-align: center;
}

.single-gallery-item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.single-gallery-item .gallery-content {
  position: absolute;
  bottom: 0;
  left: 10px;
  border: 1px solid #ffffff;
  right: 10px;
  padding: 25px 15px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-gallery-item .gallery-content::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 5px;
  background-color: #ffffff;
  z-index: -1;
}

.single-gallery-item .gallery-content span {
  display: block;
  color: #666666;
  font-size: 13px;
}

.single-gallery-item .gallery-content h3 {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 5px;
}

.single-gallery-item:hover .gallery-content,
.single-gallery-item:focus .gallery-content {
  bottom: 10px;
  opacity: 1;
  visibility: visible;
}

/*================================================
Look Book Area CSS
=================================================*/
.lookbook-area {
  padding-bottom: 30px;
}

.single-lookbook-box {
  position: relative;
  margin-bottom: 30px;
}

.single-lookbook-box .category {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #ffffff;
  padding: 15px;
  position: relative;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  margin-left: 10px;
  margin-top: -10px;
  margin-right: 10px;
}

.single-lookbook-box .category span {
  display: block;
  margin-bottom: 7px;
  font-size: 13px;
  font-weight: 600;
}

.single-lookbook-box .category span a {
  display: inline-block;
  color: #222222;
  padding-right: 3px;
  font-weight: normal;
}

.single-lookbook-box .category span a:hover {
  color: #222222;
}

.single-lookbook-box .category span:last-child {
  margin-bottom: 0;
}

/*================================================
Sizing Guide Area CSS
=================================================*/
.sizing-guide-table h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.sizing-guide-table table {
  margin-bottom: 0;
}

.sizing-guide-table table thead {
  background-color: #222222;
  color: #ffffff;
}

.sizing-guide-table table thead th {
  border-bottom: none;
  color: #ffffff;
}

.sizing-guide-table table tr td,
.sizing-guide-table table tr th {
  border-top: none;
}

.sizing-guide-table .sizing-guide-info {
  padding-left: 0;
  list-style-type: none;
  margin-top: 30px;
  margin-bottom: 0;
}

.sizing-guide-table .sizing-guide-info li {
  margin-bottom: 10px;
}

.sizing-guide-table .sizing-guide-info li:last-child {
  margin-bottom: 0;
}

.sizing-guide-table .sizing-guide-info li span {
  font-weight: 700;
}

.sizing-guide-table:not(:first-child) {
  margin-top: 30px;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 520px;
}

.error-content h3 {
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.error-content p {
  margin-bottom: 25px;
}

/*================================================
FAQs Area CSS
=================================================*/
.faq-accordion .accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: none;
}

.faq-accordion .accordion .accordion__button {
  font-weight: 500;
}

.faq-accordion .accordion .accordion__panel ul {
  padding-left: 17px;
  margin: 0 10px;
}

.faq-accordion .accordion .accordion__panel ul li {
  margin-bottom: 10px;
  line-height: 1.7;
}

.faq-accordion .accordion .accordion__panel ul li:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 15px;
  border-bottom: 1px dashed #eeeeee;
  padding-bottom: 15px;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-title {
  position: relative;
  color: #222222;
  font-size: 17px;
  padding-right: 20px;
}

.faq-accordion .accordion .accordion-item .accordion-title i {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
}

.faq-accordion .accordion .accordion-item .accordion-title.active i::before {
  content: "\f068";
}

.faq-accordion .accordion .accordion-item .accordion-content {
  display: none;
  margin-top: 20px;
}

.faq-accordion .accordion .accordion-item .accordion-content.show {
  display: block;
}

.faq-accordion .accordion .accordion-item .accordion-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-content ul li {
  color: #666666;
  position: relative;
  margin-bottom: 8px;
  line-height: 25px;
  padding-left: 12px;
}

.faq-accordion .accordion .accordion-item .accordion-content ul li::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 5px;
  height: 5px;
  background: #222222;
  border-radius: 50%;
}

.faq-accordion .accordion .accordion-item .accordion-content ul li:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-content ul li a {
  display: inline-block;
  color: #666666;
}

.faq-accordion .accordion .accordion-item .accordion-content ul li a:hover {
  color: #222222;
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-info p {
  margin-bottom: 0;
}

.contact-info .contact-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 25px;
}

.contact-info .contact-list li {
  color: #666666;
  margin-bottom: 10px;
  font-size: 13px;
  position: relative;
  padding-left: 20px;
}

.contact-info .contact-list li i {
  color: #222222;
  position: absolute;
  left: 0;
  top: 2px;
}

.contact-info .contact-list li a {
  color: #666666;
  display: inline-block;
}

.contact-info .contact-list li a:hover,
.contact-info .contact-list li a:focus {
  color: #222222;
}

.contact-info .contact-list li:last-child {
  margin-bottom: 0;
}

.contact-info .opening-hours {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 25px;
  margin-top: 15px;
}

.contact-info .opening-hours li {
  margin-bottom: 8px;
  color: #666666;
  font-size: 13px;
}

.contact-info .opening-hours li span {
  color: #222222;
}

.contact-info .opening-hours li:last-child {
  margin-bottom: 0;
}

.contact-info .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 15px;
}

.contact-info .social li {
  display: inline-block;
  margin-right: 4px;
}

.contact-info .social li a {
  color: #666666;
  border: 1px solid #eeeeee;
  width: 35px;
  height: 35px;
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
}

.contact-info .social li a:hover,
.contact-info .social li a:focus {
  background-color: #222222;
  color: #ffffff;
  border-color: #222222;
}

.contact-info .social li:last-child {
  margin-right: 0;
}

.contact-form h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-form p {
  margin-bottom: 0;
}

.contact-form #contactForm {
  margin-top: 20px;
}

.contact-form #contactForm label {
  font-size: 13px;
  text-transform: uppercase;
  color: #666666;
}

.contact-form #contactForm label span {
  text-transform: lowercase;
}

.contact-form #contactForm .form-group {
  margin-bottom: 20px;
}

.contact-form #contactForm .form-control {
  font-size: 13px;
  height: 40px;
  border: 1px solid #eeeeee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.contact-form #contactForm .form-control:focus {
  border-color: #222222;
  background-color: transparent;
}

.contact-form #contactForm textarea {
  height: auto !important;
  padding-top: 15px;
}

.contact-form #contactForm .list-unstyled {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 8px;
}

.contact-form #contactForm .list-unstyled li {
  font-size: 13px;
  color: red;
}

.contact-form #contactForm #msgSubmit {
  margin: 0;
  font-size: 18px;
}

.contact-form #contactForm #msgSubmit.text-danger,
.contact-form #contactForm #msgSubmit.text-success {
  margin-top: 15px;
}

#map {
  height: 400px;
  width: 100%;
}

/*================================================
Products Details Area CSS
=================================================*/
.products-page-gallery {
  text-align: center;
}

.products-page-gallery .product-page-gallery-main .slick-slide img {
  width: 100%;
}

.products-page-gallery .product-page-gallery-main .slick-prev,
.products-page-gallery .product-page-gallery-main .slick-next {
  z-index: 1;
  width: 45px;
  height: 45px;
  line-height: 60px;
  padding: 0;
  background-color: #ffffff;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.products-page-gallery .product-page-gallery-main .slick-prev::before,
.products-page-gallery .product-page-gallery-main .slick-next::before {
  content: '' !important;
  position: relative;
  display: inline-block;
  width: .8rem;
  height: .8rem;
  border: 0.125rem solid;
  line-height: initial;
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 0;
  border-left: 0;
  border-radius: 1px;
  border-color: #222222;
}

.products-page-gallery .product-page-gallery-main .slick-prev:hover,
.products-page-gallery .product-page-gallery-main .slick-next:hover {
  background-color: #222222;
  color: #ffffff;
}

.products-page-gallery .product-page-gallery-main .slick-prev:hover::before,
.products-page-gallery .product-page-gallery-main .slick-next:hover::before {
  border-color: #ffffff;
}

.products-page-gallery .product-page-gallery-main .slick-next {
  padding-right: 5px;
  right: 0;
}

.products-page-gallery .product-page-gallery-main .slick-next::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.products-page-gallery .product-page-gallery-main .slick-prev {
  padding-left: 5px;
  left: 0;
}

.products-page-gallery .product-page-gallery-main .slick-prev::before {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.products-page-gallery .product-page-gallery-main:hover .slick-prev,
.products-page-gallery .product-page-gallery-main:hover .slick-next {
  opacity: 1;
  visibility: visible;
}

.products-page-gallery .product-page-gallery-main:hover .slick-next {
  right: 15px;
}

.products-page-gallery .product-page-gallery-main:hover .slick-prev {
  left: 15px;
}

.products-page-gallery .slick-slide {
  outline: 0 !important;
}

.products-page-gallery .product-page-gallery-preview {
  margin-top: 10px;
  margin-left: -5px;
  margin-right: -5px;
}

.products-page-gallery .product-page-gallery-preview .slick-slide {
  margin: 0 5px;
  cursor: pointer;
}

.products-page-gallery .product-page-gallery-preview .slick-slide span {
  display: block;
  position: relative;
}

.products-page-gallery .product-page-gallery-preview .slick-slide span::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #222222;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.products-page-gallery .product-page-gallery-preview .slick-slide span:hover::before,
.products-page-gallery .product-page-gallery-preview .slick-slide span:focus::before {
  visibility: visible;
  opacity: 1;
}

.products-page-gallery .product-page-gallery-preview .slick-slide.slick-current.slick-active span::before {
  visibility: visible;
  opacity: 1;
}

.product-details-content h3 {
  font-size: 22px;
  margin-bottom: 8px;
}

.product-details-content h3 a {
  color: #222222;
}

.product-details-content h3 a:hover {
  color: #222222;
}

.product-details-content p {
  margin-bottom: 0;
}

.product-details-content .price {
  margin-bottom: 5px;
}

.product-details-content .price span {
  display: inline-block;
  font-size: 18px;
  color: #222222;
}

.product-details-content .product-review {
  margin-bottom: 12px;
}

.product-details-content .product-review .rating {
  display: inline-block;
  padding-right: 8px;
}

.product-details-content .product-review .rating i {
  color: #ffba0a;
  margin-right: 2px;
}

.product-details-content .product-review .rating-count {
  display: inline-block;
  color: #222222;
  border-bottom: 1px solid #222222;
  line-height: initial;
}

.product-details-content .product-info {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 0;
}

.product-details-content .product-info li {
  font-size: 16px;
  color: #222222;
  margin-bottom: 8px;
}

.product-details-content .product-info li:last-child {
  margin-bottom: 0;
}

.product-details-content .product-info li span {
  color: #666666;
}

.product-details-content .product-info li a {
  display: inline-block;
  font-weight: 500;
  color: #222222;
}

.product-details-content .product-info li a:hover {
  color: #222222;
}

.product-details-content .wishlist-compare-btn {
  margin-top: 20px;
}

.product-details-content .wishlist-compare-btn .btn i {
  margin-right: 3px;
}

.product-details-content .wishlist-compare-btn .btn:not(:first-child) {
  margin-left: 10px;
}

.product-details-content .product-color-switch {
  margin-top: 8px;
}

.product-details-content .product-color-switch h4 {
  font-size: 16px;
  color: #666666;
  margin-bottom: 6px;
}

.product-details-content .product-color-switch ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -4px;
  margin-left: -4px;
  padding-left: 0;
}

.product-details-content .product-color-switch ul li {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
}

.product-details-content .product-color-switch ul li a {
  display: inline-block;
  position: relative;
  border: 1px solid transparent;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.product-details-content .product-color-switch ul li a::before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.product-details-content .product-color-switch ul li a:hover,
.product-details-content .product-color-switch ul li a:focus {
  border-color: #222222;
}

.product-details-content .product-color-switch ul li a.color-white::before {
  background: #ffffff;
  border: 1px solid #eeeeee;
}

.product-details-content .product-color-switch ul li a.color-black::before {
  background: #222222;
}

.product-details-content .product-color-switch ul li a.color-red::before {
  background: red;
}

.product-details-content .product-color-switch ul li a.color-blue::before {
  background: blue;
}

.product-details-content .product-color-switch ul li a.color-green::before {
  background: green;
}

.product-details-content .product-color-switch ul li a.color-yellow::before {
  background: yellow;
}

.product-details-content .product-color-switch ul li a.color-yellowgreen::before {
  background: yellowgreen;
}

.product-details-content .product-color-switch ul li a.color-pink::before {
  background: pink;
}

.product-details-content .product-color-switch ul li a.color-violet::before {
  background: violet;
}

.product-details-content .product-color-switch ul li a.color-teal::before {
  background: teal;
}

.product-details-content .product-color-switch ul li a.color-plum::before {
  background: plum;
}

.product-details-content .product-color-switch ul li a.color-lime::before {
  background: lime;
}

.product-details-content .product-color-switch ul li a.color-blueviolet::before {
  background: blueviolet;
}

.product-details-content .product-color-switch ul li.active a {
  border-color: #222222;
}

.product-details-content .product-size-wrapper {
  margin-top: 8px;
  margin-bottom: 25px;
}

.product-details-content .product-size-wrapper h4 {
  font-size: 16px;
  color: #666666;
  margin-bottom: 8px;
}

.product-details-content .product-size-wrapper ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -8px;
  margin-left: -8px;
  padding-left: 0;
}

.product-details-content .product-size-wrapper ul li {
  display: inline-block;
  margin-left: 8px;
  margin-top: 8px;
}

.product-details-content .product-size-wrapper ul li a {
  width: 40px;
  height: 35px;
  display: inline-block;
  line-height: 35px;
  border: 1px solid #eeeeee;
  color: #858585;
  text-align: center;
}

.product-details-content .product-size-wrapper ul li a:hover,
.product-details-content .product-size-wrapper ul li a:focus {
  border-color: #222222;
  color: #222222;
}

.product-details-content .product-size-wrapper ul li.active a {
  border-color: #222222;
  color: #222222;
}

.product-details-content .product-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.product-details-content .product-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 50px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.product-details-content .product-add-to-cart .input-counter span.minus-btn {
  left: 0;
}

.product-details-content .product-add-to-cart .input-counter span.plus-btn {
  right: 0;
}

.product-details-content .product-add-to-cart .input-counter span:hover {
  color: #222222;
}

.product-details-content .product-add-to-cart .input-counter input {
  height: 45px;
  color: #222222;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.product-details-content .product-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: #222222;
}

.product-details-content .product-add-to-cart .input-counter input:-ms-input-placeholder {
  color: #222222;
}

.product-details-content .product-add-to-cart .input-counter input::-ms-input-placeholder {
  color: #222222;
}

.product-details-content .product-add-to-cart .input-counter input::placeholder {
  color: #222222;
}

.product-details-content .product-add-to-cart .btn {
  height: 45px;
  top: -2px;
  position: relative;
}

.product-details-content .product-add-to-cart .btn i {
  margin-right: 2px;
}

.product-details-content .product-info-btn {
  margin-bottom: 25px;
}

.product-details-content .product-info-btn a {
  color: #666666;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  position: relative;
  line-height: initial;
}

.product-details-content .product-info-btn a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1.5px;
  background: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.product-details-content .product-info-btn a i {
  color: #222222;
  margin-right: 3px;
  font-size: 15px;
}

.product-details-content .product-info-btn a:hover {
  color: #222222;
}

.product-details-content .product-info-btn a:hover::before {
  width: 100%;
}

.product-details-content .product-info-btn a:not(:first-child) {
  margin-left: 15px;
}

.product-details-content .buy-checkbox-btn {
  margin-top: 25px;
}

.product-details-content .buy-checkbox-btn input {
  display: none;
}

.product-details-content .buy-checkbox-btn .cbx {
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.product-details-content .buy-checkbox-btn .cbx span {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.product-details-content .buy-checkbox-btn .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  -webkit-transform: scale(1);
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #ebebeb;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.product-details-content .buy-checkbox-btn .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.product-details-content .buy-checkbox-btn .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #222222;
  display: block;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.product-details-content .buy-checkbox-btn .cbx span:last-child {
  padding-left: 10px;
  color: #666666;
}

.product-details-content .buy-checkbox-btn .cbx:hover span:first-child {
  border-color: #222222;
}

.product-details-content .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child {
  background: #222222;
  border-color: #222222;
  -webkit-animation: wave 0.4s ease;
  animation: wave 0.4s ease;
}

.product-details-content .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.product-details-content .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child:before {
  -webkit-transform: scale(3.5);
  transform: scale(3.5);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.product-details-content .buy-checkbox-btn .item:not(:first-child) {
  margin-top: 15px;
}

.product-details-content .buy-checkbox-btn .btn {
  display: block;
}

.product-details-content .custom-payment-options {
  margin-top: 20px;
}

.product-details-content .custom-payment-options span {
  display: block;
  color: #666666;
  margin-bottom: 5px;
}

.product-details-content .custom-payment-options .payment-methods a {
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
}

.product-details-content .custom-payment-options .payment-methods a img {
  width: 40px;
}

.products-details-tab {
  /* margin-top: -80px;     this one commented by Nooruddin due to problem of new image slider*/
  margin-top: -1px;
}


.products-details-tab .tabs {
  list-style-type: none;
  margin: 0 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: center;
  padding: 0;
  border-bottom: 1px dashed #eeeeee;
  padding-bottom: 15px;
}

.products-details-tab .tabs li {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.products-details-tab .tabs li .tabs-nav-text,
.products-details-tab .tabs li a {
  display: inline-block;
  font-size: 14px;
  position: relative;
  padding-left: 18px;
  text-transform: uppercase;
  color: #666666;
  cursor: pointer;
}

.products-details-tab .tabs li .tabs-nav-text .dot,
.products-details-tab .tabs li a .dot {
  position: absolute;
  top: 4px;
  left: 0;
  width: 12px;
  height: 12px;
  border: 1px solid #666666;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.products-details-tab .tabs li .tabs-nav-text .dot::before,
.products-details-tab .tabs li a .dot::before {
  position: absolute;
  top: 0;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  background: #666666;
  margin: 1px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.products-details-tab .tabs li .tabs-nav-text:hover,
.products-details-tab .tabs li .tabs-nav-text:focus,
.products-details-tab .tabs li a:hover,
.products-details-tab .tabs li a:focus {
  color: #222222;
}

.products-details-tab .tabs li .tabs-nav-text:hover .dot,
.products-details-tab .tabs li .tabs-nav-text:focus .dot,
.products-details-tab .tabs li a:hover .dot,
.products-details-tab .tabs li a:focus .dot {
  border-color: #222222;
}

.products-details-tab .tabs li .tabs-nav-text:hover .dot::before,
.products-details-tab .tabs li .tabs-nav-text:focus .dot::before,
.products-details-tab .tabs li a:hover .dot::before,
.products-details-tab .tabs li a:focus .dot::before {
  background: #222222;
}

.products-details-tab .tabs li.current .tabs-nav-text,
.products-details-tab .tabs li.current a {
  color: #222222;
}

.products-details-tab .tabs li.current .tabs-nav-text .dot,
.products-details-tab .tabs li.current a .dot {
  border-color: #222222;
}

.products-details-tab .tabs li.current .tabs-nav-text .dot::before,
.products-details-tab .tabs li.current a .dot::before {
  background: #222222;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content p {
  margin-bottom: 20px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content ul,
.products-details-tab .tab_content .tabs_item .products-details-tab-content ol {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content ul li,
.products-details-tab .tab_content .tabs_item .products-details-tab-content ol li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 12px;
  color: #666666;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content ul li::before,
.products-details-tab .tab_content .tabs_item .products-details-tab-content ol li::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  width: 5px;
  height: 5px;
  background: #222222;
  border-radius: 50%;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content ul li:last-child,
.products-details-tab .tab_content .tabs_item .products-details-tab-content ol li:last-child {
  margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .table {
  margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .table.table-striped tbody tr td {
  border-top: none;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .table.table-bordered tbody tr td {
  vertical-align: middle;
  color: #666666;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title {
  position: relative;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating {
  display: inline-block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating .fas.fa-star {
  color: #ffba0a;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating i {
  color: #ebebeb;
  margin-right: 2px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 5px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments {
  margin-top: 35px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
  margin-top: 30px;
  position: relative;
  padding-right: 200px;
  border-top: 1px dashed #eeeeee;
  padding-top: 30px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating .fas.fa-star {
  color: #ffba0a;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating i {
  color: #ebebeb;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item h3 {
  margin-top: 10px;
  margin-bottom: 8px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span {
  margin-bottom: 10px;
  font-size: 13px;
  display: block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span strong {
  font-weight: 600;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item p {
  margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
  position: absolute;
  right: 20px;
  color: #666666;
  top: 40px;
  text-decoration: underline;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link:hover {
  color: #222222;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form {
  margin-top: 35px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group label {
  font-size: 13px;
  color: #666666;
  text-transform: uppercase;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group textarea {
  padding-top: 15px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .btn {
  margin-top: 10px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating {
  margin-bottom: 1rem;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating p {
  margin-bottom: 5px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-source {
  width: 0;
  height: 0;
  margin-top: 5px;
  visibility: hidden;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-source svg {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 0.15rem;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 105px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating label {
  cursor: pointer;
  margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating label .star {
  color: transparent;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating label:hover~label .star,
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating svg.star:hover,
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating input[name="star"]:focus~label .star,
.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating input[name="star"]:checked~label .star {
  color: #f2b01e;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating svg {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 0.15rem;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating input[name="star"] {
  display: inline-block;
  width: 0;
  opacity: 0;
  margin-left: -2px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating input[name="star"]:checked+label {
  -webkit-animation: scaleup 1s;
  animation: scaleup 1s;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating .star-rating input[name="star"]:checked+label .star {
  -webkit-animation: starred 0.5s;
  animation: starred 0.5s;
}

.sizeGuideModal .modal-dialog {
  max-width: 900px;
  margin: 0 auto;
}

.sizeGuideModal .modal-content {
  border: none;
  padding: 40px;
  border-radius: 0;
}

.sizeGuideModal .modal-content button.close {
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  opacity: 1;
  color: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  line-height: 35px;
  padding: 0;
  margin: 0;
  font-size: 20px;
  width: 35px;
  text-shadow: unset;
  height: 35px;
}

.sizeGuideModal .modal-content button.close:hover {
  background-color: red;
  color: #ffffff;
}

.sizeGuideModal .modal-content .modal-sizeguide {
  text-align: center;
}

.sizeGuideModal .modal-content .modal-sizeguide h3 {
  font-size: 18px;
  margin-bottom: 8px;
}

.sizeGuideModal .modal-content .modal-sizeguide p {
  margin-bottom: 25px;
}

.sizeGuideModal .modal-content .modal-sizeguide .table {
  margin-bottom: 0;
}

.sizeGuideModal .modal-content .modal-sizeguide .table thead {
  background-color: #222222;
  color: #ffffff;
}

.sizeGuideModal .modal-content .modal-sizeguide .table thead th {
  border-bottom: none;
  color: #ffffff;
}

.sizeGuideModal .modal-content .modal-sizeguide .table tr td,
.sizeGuideModal .modal-content .modal-sizeguide .table tr th {
  border-top: none;
}

.productShippingModal .modal-dialog {
  max-width: 900px;
  margin: 0 auto;
}

.productShippingModal .modal-content {
  border: none;
  padding: 40px;
  border-radius: 0;
}

.productShippingModal .modal-content button.close {
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  opacity: 1;
  color: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  line-height: 35px;
  padding: 0;
  margin: 0;
  font-size: 20px;
  width: 35px;
  text-shadow: unset;
  height: 35px;
}

.productShippingModal .modal-content button.close:hover {
  background-color: red;
  color: #ffffff;
}

.productShippingModal .modal-content .shipping-content h3 {
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 30px;
}

.productShippingModal .modal-content .shipping-content h3:first-child {
  margin-top: 0;
}

.productShippingModal .modal-content .shipping-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.productShippingModal .modal-content .shipping-content ul li {
  margin-bottom: 12px;
  color: #666666;
  position: relative;
  padding-left: 11px;
}

.productShippingModal .modal-content .shipping-content ul li::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #222222;
}

.productShippingModal .modal-content .shipping-content ul li:last-child {
  margin-bottom: 0;
}

@-webkit-keyframes wave {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes wave {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.related-products-area {
  margin-top: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #eeeeee;
}

.related-products-area .trending-products-slides-two.owl-theme .owl-nav.disabled+.owl-dots {
  margin-bottom: 0;
}

.col-lg-4.col-md-12:first-child .product-single-aside {
  border-right: 1px solid #ebebeb;
  border-left: none;
  padding-right: 15px;
  padding-left: 0;
}

.product-single-aside {
  height: 100%;
  border-left: 1px solid #ebebeb;
  padding-left: 15px;
}

.product-single-aside .aside-trending-products {
  overflow: hidden;
  position: relative;
  text-align: center;
  margin-top: 25px;
}

.product-single-aside .aside-trending-products .category {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.product-single-aside .aside-trending-products .category h4 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 7px;
  position: relative;
  z-index: 1;
}

.product-single-aside .aside-trending-products .category span {
  color: #666666;
  position: relative;
  display: block;
  z-index: 1;
}

.product-single-aside .aside-trending-products img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.product-single-aside .aside-trending-products a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.product-single-aside .aside-trending-products:hover img,
.product-single-aside .aside-trending-products:focus img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.product-single-aside .aside-trending-products:hover .category,
.product-single-aside .aside-trending-products:focus .category {
  bottom: 10px;
}

.product-single-aside .aside-trending-products:first-child {
  margin-top: 0;
}

.product-single-aside .services-aside .facility-block {
  margin-bottom: 20px;
}

.product-single-aside .services-aside .facility-block h3 {
  font-size: 16px;
  position: relative;
  margin-bottom: 8px;
  padding-left: 25px;
}

.product-single-aside .services-aside .facility-block h3 i {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.product-single-aside .services-aside .facility-block:last-child {
  margin-bottom: 0;
}

.product-single-aside .products-payments-info {
  text-align: center;
  position: relative;
  margin-top: 45px;
  border: 1px solid #eeeeee;
  padding: 20px;
  padding-top: 45px;
}

.product-single-aside .products-payments-info span {
  display: inline-block;
  color: #858585;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  background-color: #ffffff;
  padding-right: 10px;
  padding-left: 10px;
}

.product-single-aside .products-payments-info .payments-type a {
  display: inline-block;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
  margin-top: 30px;
  text-align: center;
}

.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  margin: 0 3px;
  display: inline-block;
  background-color: #ffffff;
  line-height: 40px;
  color: #222222;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
  font-size: 16px;
  font-weight: 600;
}

.pagination-area .page-numbers.current,
.pagination-area .page-numbers:hover,
.pagination-area .page-numbers:focus {
  background: #222222;
  color: #ffffff;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

/*================================================
Sidebar Area CSS
=================================================*/
.widget-area .widget {
  margin-top: 30px;
}

.widget-area .widget:first-child {
  margin-top: 0;
}

.widget-area .widget .widget-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-size: 18px;
}

.widget-area .widget_comero_posts_thumb {
  position: relative;
  overflow: hidden;
}

.widget-area .widget_comero_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 20px;
}

.widget-area .widget_comero_posts_thumb .item:last-child {
  margin-bottom: 0;
}

.widget-area .widget_comero_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}

.widget-area .widget_comero_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.widget-area .widget_comero_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(../images/blog1.jpg);
}

.widget-area .widget_comero_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(../images/blog3.jpg);
}

.widget-area .widget_comero_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(../images/blog4.jpg);
}

.widget-area .widget_comero_posts_thumb .item .info {
  overflow: hidden;
}

.widget-area .widget_comero_posts_thumb .item .info time {
  display: block;
  color: #666666;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.widget-area .widget_comero_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;
}

.widget-area .widget_comero_posts_thumb .item .info .title a {
  color: #222222;
}

.widget-area .widget_recent_entries ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  color: #666666;
  padding-left: 15px;
  line-height: 1.5;
  font-size: 14px;
}

.widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_recent_entries ul li::before {
  background: #222222;
  position: absolute;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 6px;
}

.widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #666666;
  margin-top: 4px;
}

.widget-area .widget_recent_entries ul li a {
  color: #222222;
}

.widget-area .widget_recent_comments ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.widget-area .widget_recent_comments ul li {
  position: relative;
  margin-bottom: 12px;
  color: #666666;
  padding-left: 15px;
  line-height: 1.5;
  font-size: 14px;
}

.widget-area .widget_recent_comments ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_recent_comments ul li::before {
  background: #222222;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 6px;
  position: absolute;
}

.widget-area .widget_recent_comments ul li span {
  display: inline-block;
}

.widget-area .widget_recent_comments ul li a {
  display: inline-block;
  color: #222222;
}

.widget-area .widget_archive ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.widget-area .widget_archive ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 15px;
  font-size: 14px;
}

.widget-area .widget_archive ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_archive ul li::before {
  background: #222222;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 6px;
  position: absolute;
}

.widget-area .widget_archive ul li a {
  color: #222222;
}

.widget-area .widget_categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  color: #666666;
  padding-left: 15px;
  font-size: 14px;
}

.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_categories ul li::before {
  background: #222222;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 6px;
  position: absolute;
}

.widget-area .widget_categories ul li a {
  display: inline-block;
  color: #222222;
}

.widget-area .widget_categories ul li .post-count {
  float: right;
}

.widget-area .widget_meta ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.widget-area .widget_meta ul li {
  position: relative;
  margin-bottom: 12px;
  color: #666666;
  padding-left: 15px;
  font-size: 14px;
}

.widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_meta ul li::before {
  background: #222222;
  position: absolute;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 6px;
}

.widget-area .widget_meta ul li a {
  color: #222222;
}

.widget-area .tagcloud a {
  display: inline-block;
  font-size: 13px !important;
  color: #858585;
  padding: 7px 10px;
  border: 1px solid #eeeeee;
  margin-top: 8px;
  margin-right: 4px;
}

.widget-area .tagcloud a:hover,
.widget-area .tagcloud a:focus {
  background-color: #222222;
  color: #ffffff;
  border-color: #222222;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table table {
  margin-bottom: 0;
}

.cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 15px 0;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  font-size: 15px;
}

.cart-table table tbody tr td {
  vertical-align: middle;
  color: #666666;
  padding-left: 0;
  padding-right: 0;
  border-color: #eaedff;
  border-left: none;
  border-right: none;
}

.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}

.cart-table table tbody tr td.product-thumbnail a img {
  width: 100px;
}

.cart-table table tbody tr td.product-name a {
  color: #666666;
  font-weight: 600;
  display: inline-block;
  font-size: 15px;
}

.cart-table table tbody tr td.product-name a:hover {
  color: #222222;
}

.cart-table table tbody tr td.product-name ul {
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 0;
  list-style-type: none;
}

.cart-table table tbody tr td.product-name ul li {
  color: #858585;
  font-size: 12px;
  margin-bottom: 5px;
}

.cart-table table tbody tr td.product-name ul li:last-child {
  margin-bottom: 0;
}

.cart-table table tbody tr td.product-name ul li strong {
  font-weight: 600;
}

.cart-table table tbody tr td.product-price span {
  font-weight: 600;
}

.cart-table table tbody tr td.product-subtotal .remove {
  color: red;
  float: right;
  position: relative;
  top: -1px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 50px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
  left: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
  right: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: #222222;
}

.cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: #222222;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
  color: #222222;
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
  color: #222222;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
  color: #222222;
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: #222222;
}

.cart-table table tbody tr td.product-subtotal {
  overflow: hidden;
}

.cart-table table tbody tr td.product-subtotal span {
  font-weight: 600;
}

.cart-buttons {
  margin-top: 30px;
  border-bottom: 1px solid #eaedff;
  padding-bottom: 30px;
}

.cart-buttons label {
  margin-bottom: 0;
}

.cart-buttons label input {
  position: relative;
  top: 1px;
  margin-right: 4px;
}

.cart-totals {
  background: #ffffff;
  padding: 40px;
  max-width: 600px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin: 45px auto 0;
}

.cart-totals h3 {
  margin-bottom: 20px;
  font-size: 19px;
}

.cart-totals ul {
  padding: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border: 1px solid #eaedff;
  padding: 10px 15px;
  color: #222222;
  font-weight: 600;
  overflow: hidden;
}

.cart-totals ul li:first-child {
  border-bottom: none;
}

.cart-totals ul li:last-child {
  border-top: none;
}

.cart-totals ul li span {
  float: right;
  color: #666666;
  font-weight: normal;
}

.cart-totals ul li span b {
  font-weight: 600;
}

/*================================================
Checkout CSS
=================================================*/
.user-actions {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 18px 25px 15px;
  margin-bottom: 35px;
  border-top: 3px solid #222222;
}

.user-actions i {
  color: #222222;
  margin-right: 3px;
}

.user-actions span {
  display: inline-block;
  font-weight: 600;
  color: #222222;
}

.user-actions span a {
  font-weight: 500;
  display: inline-block;
  color: #666666;
}

.user-actions span a:hover,
.user-actions span a:focus {
  color: #222222;
}

.checkout-area .title {
  font-size: 18px;
  margin-bottom: 25px;
}

textarea.form-control {
  height: auto !important;
}

.billing-details .form-group {
  margin-bottom: 20px;
}

.billing-details .form-group label {
  display: block;
  color: #222222;
  margin-bottom: 8px;
  font-size: 14px;
}

.billing-details .form-group label .required {
  color: red;
}

.billing-details .form-group .select-box .nice-select {
  line-height: 45px;
  float: unset;
  font-size: 13px;
}

.billing-details .form-group .select-box .nice-select .current {
  color: #666666;
}

.billing-details .form-group .select-box .nice-select .list {
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.billing-details .form-group .select-box .nice-select .list .option {
  line-height: 35px;
  min-height: 30px;
}

.billing-details .form-group .form-control {
  border: 1px solid #eeeeee;
}

.billing-details .form-group .form-control:focus {
  border-color: #222222;
}

.billing-details .form-group textarea {
  padding-top: 15px;
}

.billing-details .form-check {
  margin-bottom: 15px;
}

.billing-details .form-check .form-check-label {
  color: #666666;
}

.billing-details .form-check label {
  font-size: 14px;
  font-weight: 400;
}

.order-details .order-table table {
  margin-bottom: 0;
  text-align: left;
}

.order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border-color: #eaedff;
  text-transform: uppercase;
  padding: 15px .75rem;
  font-weight: 600;
  font-size: 15px;
}

.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: #666666;
  border-color: #eaedff;
  font-size: 14px;
}

.order-details .order-table table tbody tr td.product-name a {
  color: #666666;
  display: inline-block;
  font-size: 15px;
}

.order-details .order-table table tbody tr td.product-name a:hover {
  color: #222222;
}

.order-details .order-table table tbody tr td.order-subtotal span,
.order-details .order-table table tbody tr td.order-shipping span,
.order-details .order-table table tbody tr td.total-price span {
  color: #222222;
  font-weight: 600;
}

.order-details .payment-method {
  margin-top: 40px;
  border-top: 1px solid #eaedff;
  padding-top: 35px;
}

.order-details .payment-method p {
  font-size: 14px;
}

.order-details .payment-method p [type="radio"]:checked,
.order-details .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.order-details .payment-method p [type="radio"]:checked+label,
.order-details .payment-method p [type="radio"]:not(:checked)+label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  font-weight: 600;
  color: #222222;
  position: relative;
  margin-bottom: 8px;
}

.order-details .payment-method p [type="radio"]:checked+label::before,
.order-details .payment-method p [type="radio"]:not(:checked)+label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}

.order-details .payment-method p [type="radio"]:checked+label::after,
.order-details .payment-method p [type="radio"]:not(:checked)+label::after {
  content: '';
  width: 12px;
  height: 12px;
  background: #222222;
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.order-details .payment-method p [type="radio"]:not(:checked)+label::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.order-details .payment-method p [type="radio"]:checked+label::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.order-details .order-btn {
  margin-top: 15px;
}

.order-details .order-btn span {
  display: block;
}

.order-details .order-btn span .btn {
  width: 100%;
}

.order-details .order-btn span .btn-primary.btn-disabled {
  background-color: #717070;
  border-color: #717070;
  cursor: not-allowed;
}

.order-details .order-btn span .btn-primary.btn-disabled:hover {
  background-color: transparent;
  border-color: #222222;
}

/*================================================
Compare Area CSS
=================================================*/
.products-compare-table .remove-btn {
  margin-bottom: 15px;
}

.products-compare-table .remove-btn a {
  color: red;
  display: inline-block;
}

/*================================================
Login Area CSS
=================================================*/
.login-area .section-title {
  margin-bottom: 25px;
}

.login-form {
  padding-right: 15px;
}

.login-form .form-group {
  margin-bottom: 20px;
}

.login-form .form-group label {
  display: block;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.login-form .form-group .form-control {
  border: 1px solid #f5f5f5;
}

.login-form .form-group .form-control:focus {
  border-color: #222222;
}

.login-form .btn {
  display: block;
  width: 100%;
  padding: 14px 25px;
}

.login-form .forgot-password {
  display: inline-block;
  margin-top: 15px;
  color: #666666;
  position: relative;
  font-size: 15px;
}

.login-form .forgot-password::before {
  width: 100%;
  height: 1.5px;
  background: #666666;
  bottom: 0;
  left: 0;
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  content: '';
}

.login-form .forgot-password:hover {
  color: #222222;
}

.login-form .forgot-password:hover::before {
  width: 0;
}

.new-customer-content {
  padding-left: 15px;
}

.new-customer-content span {
  display: block;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 8px;
}

.new-customer-content p {
  margin-bottom: 20px;
}

/*================================================
Signup Area CSS
=================================================*/
.signup-area .section-title {
  margin-bottom: 25px;
}

.signup-content {
  max-width: 750px;
  margin: 0 auto;
}

.signup-form .form-group {
  margin-bottom: 20px;
}

.signup-form .form-group label {
  display: block;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.signup-form .form-group .form-control {
  border: 1px solid #f5f5f5;
}

.signup-form .form-group .form-control:focus {
  border-color: #222222;
}

.signup-form .btn {
  display: block;
  width: 100%;
  padding: 14px 25px;
}

.signup-form .return-store {
  display: inline-block;
  margin-top: 15px;
  color: #666666;
  position: relative;
  font-size: 15px;
}

.signup-form .return-store::before {
  width: 100%;
  height: 1.5px;
  background: #666666;
  bottom: 0;
  left: 0;
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  content: '';
}

.signup-form .return-store:hover {
  color: #222222;
}

.signup-form .return-store:hover::before {
  width: 0;
}

/*================================================
Customer Service Area CSS
=================================================*/
.customer-service-content h3 {
  text-transform: uppercase;
  position: relative;
  padding-left: 23px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: normal;
}

.customer-service-content h3 i {
  position: absolute;
  top: 1px;
  left: 0;
}

.customer-service-content h3:not(:first-child) {
  margin-top: 30px;
}

.customer-service-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.customer-service-content ul li {
  color: #666666;
  margin-bottom: 10px;
  position: relative;
  padding-left: 11px;
}

.customer-service-content ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 7.5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #222222;
}

.customer-service-content ul li:last-child {
  margin-bottom: 0;
}

.customer-service-content ul li a {
  display: inline-block;
  color: #666666;
}

.customer-service-content ul li a:hover {
  color: #222222;
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  background-color: #ffffff;
  padding-top: 40px;
  position: relative;
  z-index: 1;
  border-top: 1px solid #f3f3f3;
}

.single-footer-widget .logo {
  margin-bottom: 15px;
}

.single-footer-widget .logo a {
  display: inline-block;
}

.single-footer-widget h3 {
  font-size: 17px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 14px;
  padding-bottom: 5px;
}

.single-footer-widget h3::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ebebeb;
  width: 45px;
  height: 2px;
}

.single-footer-widget p {
  font-size: 13px;
}

.single-footer-widget .quick-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.single-footer-widget .quick-links li {
  color: #666666;
  margin-bottom: 8px;
  font-size: 13px;
}

.single-footer-widget .quick-links li a {
  color: #666666;
  display: inline-block;
}

.single-footer-widget .quick-links li a:hover,
.single-footer-widget .quick-links li a:focus {
  color: #222222;
  padding-left: 5px;
}

.single-footer-widget .quick-links li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .information-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.single-footer-widget .information-links li {
  color: #666666;
  margin-bottom: 8px;
  font-size: 13px;
}

.single-footer-widget .information-links li a {
  color: #666666;
  display: inline-block;
}

.single-footer-widget .information-links li a:hover,
.single-footer-widget .information-links li a:focus {
  color: #222222;
  padding-left: 5px;
}

.single-footer-widget .information-links li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-contact-info {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.single-footer-widget .footer-contact-info li {
  color: #666666;
  margin-bottom: 10px;
  font-size: 13px;
  position: relative;
  padding-left: 20px;
}

.single-footer-widget .footer-contact-info li i {
  color: #222222;
  position: absolute;
  left: 0;
  top: 4px;
}

.single-footer-widget .footer-contact-info li a {
  color: #666666;
  display: inline-block;
}

.single-footer-widget .footer-contact-info li a:hover,
.single-footer-widget .footer-contact-info li a:focus {
  color: #222222;
}

.single-footer-widget .footer-contact-info li:last-child {
  margin-bottom: 0;
}

.copyright-area {
  margin-top: 40px;
  background-color: #f5f5f5;
  padding-top: 15px;
  padding-bottom: 15px;
}

.copyright-area .payment-card {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: right;
}

.copyright-area .payment-card li {
  display: inline-block;
}

.copyright-area a {
  display: inline-block;
  color: #1b28ce;
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 25px;
  right: 15px;
  color: #ffffff;
  background-color: #222222;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.go-top i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.opacityZero {
  opacity: 0;
}

.opacityOne {
  opacity: 1 !important;
}

.block {
  display: block;
}

.none {
  display: none;
}

.thank-you-area {
  text-align: center;
  padding-top: 150px;
  padding-bottom: 150px;
}

.thank-you-area h1 {
  margin-bottom: 15px;
}

.main-banner-three.item-bg4 {
  position: relative;
}

.main-banner-three.item-bg4::before {
  content: '';
  background-color: #000000;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: .20;
}

.main-banner-three.item-bg4 .main-banner-content {
  position: relative;
}

.accordion__button:focus {
  outline: 0;
}

/* Home five style */
.header-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 999;
}

.navbar-area .container-fluid {
  padding-left: 35px;
  padding-right: 35px;
}

.navbar-area.bg-black .comero-nav {
  background-color: rgba(0, 0, 0, 0.36);
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item a:hover,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item a:focus,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item a.active {
  color: #ffffff;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item:hover a,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item:focus a,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item.active a {
  color: #ffffff;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #666666;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #222222;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #666666;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #222222;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #666666;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #222222;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #222222;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #222222;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #222222;
}

.navbar-area.bg-black .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #222222;
}

.navbar-area.bg-black .comero-nav .others-option .option-item a {
  color: #ffffff;
}

.navbar-area.bg-black .comero-nav .others-option .option-item a::before {
  background: #ffffff;
}

.navbar-area.bg-black .comero-nav .others-option .option-item a:hover,
.navbar-area.bg-black .comero-nav .others-option .option-item a:focus {
  color: #ffffff;
}

.navbar-area.bg-black .comero-nav .others-option .option-item::before {
  background: #ffffff;
}

.navbar-area.bg-black .comero-nav .others-option .option-item .search-btn {
  color: #ffffff;
}

.navbar-area.bg-black .comero-nav .others-option .option-item .close-btn {
  color: #ffffff;
}

.navbar-area.bg-black .comero-nav.is-sticky {
  background-color: black !important;
}

/* Home slider */
.home-slides-two.owl-theme .owl-nav {
  margin-top: 0;
}

.home-slides-two.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 30px;
  top: 58%;
  -webkit-transform: translateY(-58%);
  transform: translateY(-58%);
  margin: 0;
  outline: 0;
  width: 45px;
  height: 45px;
  line-height: 38px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: rgba(255, 255, 255, 0.88);
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.88);
  font-size: 20px;
  border-radius: 50%;
}

.home-slides-two.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #ffffff;
  color: #222222;
}

.home-slides-two.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 30px;
}

.banner-section {
  height: 850px;
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.item-bg5 {
  background-image: url(../images/main-banner5.jpg);
}

.item-bg6 {
  background-image: url(../images/main-banner6.jpg);
}

.banner-content {
  margin-top: 80px;
  max-width: 520px;
  position: relative;
  z-index: 1;
}

.banner-content .sub-title {
  font-size: 14px;
  color: #ffffff;
  display: block;
  margin-bottom: 11px;
}

.banner-content h1 {
  font-size: 34px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.banner-content p {
  line-height: initial;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 30px;
}

.banner-content .btn-primary:not(:disabled):not(.disabled).active,
.banner-content .btn-primary:not(:disabled):not(.disabled):active,
.show>.banner-content .btn-primary.dropdown-toggle {
  color: #222222;
  border-color: #222222;
  background-color: #ffffff;
}

.banner-content .btn-primary:hover,
.banner-content .btn-primary:focus {
  color: #222222;
  border-color: #222222;
  background-color: #ffffff;
}

.banner-content::before {
  content: '';
  position: absolute;
  top: -50px;
  width: 100%;
  max-width: 380px;
  text-align: center;
  height: 285px;
  left: -50px;
  z-index: -1;
  border: 10px solid rgba(255, 255, 255, 0.2);
}

.category-boxes-area {
  padding-bottom: 30px;
}

.single-category-boxes {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.single-category-boxes img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;

}

.single-category-boxes .link-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.single-category-boxes .shop-now-btn {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: #ffffff;
  text-transform: uppercase;
  padding: 12px 30px;
  color: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 500;
}

.single-category-boxes .shop-now-btn:hover {
  background-color: #222222;
  color: #ffffff;
}

.single-category-boxes h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  position: absolute;
  left: 25px;
  top: 25px;
  font-size: 18px;
  font-weight: 700;
}

.single-category-boxes:hover img {
  -webkit-transform: scale(1.1) rotate(4deg);
  transform: scale(1.1) rotate(4deg);
}

.single-category-boxes:hover .shop-now-btn {
  background-color: #222222;
  color: #ffffff;
}

/* All products slides two */
.all-products-slides-two.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 15px;
  margin-bottom: 30px;
}

/* End home five style */
/* Start home Six, Seven, Eight, Nine, Ten & Eleven Style */
/*================================================
Template Boxed Area CSS
=================================================*/
.boxed-layout-wrapper {
  background: #f7f8fa;
}

.boxed-layout-wrapper .boxed-layout-content {
  background: #ffffff;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.boxed-layout-wrapper .navbar-area.is-sticky {
  right: 0;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.boxed-layout-wrapper .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  left: auto;
  right: 0;
}

.boxed-layout-wrapper .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: auto;
  right: 240px;
}

.boxed-layout-wrapper .comero-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  right: auto;
  left: 240px;
}

.boxed-layout-wrapper .comero-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu {
  max-width: 1200px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

/*================================================
Top Header Area CSS
=================================================*/
.top-header-logo {
  text-align: center;
}

.top-header-others-option {
  text-align: right;
}

.top-header-others-option .option-item {
  display: inline-block;
  position: relative;
  margin-right: 17px;
  line-height: initial;
}

.top-header-others-option .option-item a {
  color: #222222;
  text-transform: uppercase;
  position: relative;
}

.top-header-others-option .option-item a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-header-others-option .option-item a:hover,
.top-header-others-option .option-item a:focus {
  color: #222222;
}

.top-header-others-option .option-item a:hover::before,
.top-header-others-option .option-item a:focus::before {
  width: 100%;
}

.top-header-others-option .option-item::before {
  content: '';
  position: absolute;
  right: -12px;
  top: 0.5px;
  width: 1px;
  height: 12px;
  background: #888888;
}

.top-header-others-option .option-item:last-child {
  margin-right: 0;
}

.top-header-others-option .option-item:last-child::before {
  display: none;
}

.top-header-others-option .option-item .search-btn {
  cursor: pointer;
}

.top-header-others-option .option-item .close-btn {
  cursor: pointer;
  display: none;
}

.top-header-others-option .option-item .close-btn.active {
  display: block;
}

.top-header-others-option .search-overlay.search-popup {
  margin-top: 20px;
}

.top-header-style-two {
  padding-top: 15px;
  padding-bottom: 15px;
}

.top-header-style-two .top-header-right-nav {
  text-align: left;
}

.top-header-social {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.top-header-social li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
}

.top-header-social li a {
  text-transform: uppercase;
  font-size: 13px;
  color: #222222;
}

.top-header-social li a:hover {
  color: #666666;
}

.top-header-social li::before {
  content: '';
  position: absolute;
  right: -10px;
  top: 3.2px;
  height: 10px;
  width: 1px;
  background: #222222;
}

.top-header-social li:last-child {
  margin-right: 0;
}

.top-header-social li:last-child::before {
  display: none;
}

.top-header-discount-info {
  text-align: center;
}

.top-header-discount-info p {
  margin-bottom: 0;
}

.top-header-discount-info p strong {
  color: #222222;
}

.top-header-discount-info p a {
  display: inline-block;
  color: #222222;
  font-weight: 700;
  position: relative;
}

.top-header-discount-info p a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 100%;
  height: 1px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #222222;
}

.top-header-discount-info p a:hover::before {
  width: 0;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area.navbar-style-two .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner.item-bg7 {
  background-image: url(../images/main-banner-image/banner-new-bg1.jpg);
}

.main-banner.item-bg8 {
  background-image: url(../images/main-banner-image/banner-new-bg2.jpg);
}

.main-banner.item-bg9 {
  background-image: url(../images/main-banner-image/banner-new-bg3.jpg);
}

.main-banner.item-bg10 {
  background-image: url(../images/main-banner-image/banner-new-bg4.jpg);
}

.main-banner.item-bg11 {
  background-image: url(../images/main-banner-image/banner-new-bg5.jpg);
}

.main-banner.item-bg12 {
  background-image: url(../images/main-banner-image/banner-new-bg6.jpg);
}

.main-banner.item-bg13 {
  background-image: url(../images/main-banner-image/banner-new-bg7.jpg);
}

.main-banner.item-bg14 {
  background-image: url(../images/main-banner-image/banner-new-bg8.jpg);
}

.main-banner.item-bg15 {
  background-image: url(../images/main-banner-image/banner-new-bg9.jpg);
}

.main-banner.item-bg16 {
  background-image: url(../images/main-banner-image/banner-new-bg10.jpg);
}

.main-banner.item-bg17 {
  background-image: url(../images/main-banner-image/banner-new-bg11.jpg);
}

.main-banner.item-bg18 {
  background-image: url(../images/main-banner-image/banner-new-bg12.jpg);
}

.main-banner.item-bg19 {
  background-image: url(../images/main-banner-image/banner-new-bg13.jpg);
}

.main-banner.item-bg20 {
  background-image: url(../images/main-banner-image/banner-new-bg14.jpg);
}

.main-banner.item-bg21 {
  background-image: url(../images/main-banner-image/banner-new-bg15.jpg);
}

.main-banner.item-bg22 {
  background-image: url(../images/main-banner-image/banner-new-bg16.jpg);
}

.main-banner-content.white-color .btn-light {
  color: #ffffff;
  border-color: #ffffff;
}

.main-banner-content.white-color .btn-light:hover,
.main-banner-content.white-color .btn-light:focus {
  color: #222222;
  background-color: #ffffff;
  border-color: #ffffff;
}

.main-banner-content.text-center {
  margin-left: auto;
  margin-right: auto;
}

.home-slides-three.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 0;
}

.home-slides-three.owl-theme .owl-dots {
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.home-slides-three.owl-theme .owl-dots .owl-dot {
  display: block;
}

.home-slides-three.owl-theme .owl-dots .owl-dot span {
  width: 15px !important;
  height: 15px !important;
  background-color: transparent !important;
  border: 1px solid transparent;
  position: relative;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.home-slides-three.owl-theme .owl-dots .owl-dot span::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 2px;
  background-color: #ffffff;
}

.home-slides-three.owl-theme .owl-dots .owl-dot:hover span,
.home-slides-three.owl-theme .owl-dots .owl-dot.active span {
  border-color: #ffffff;
}

.home-slides-three.owl-theme.black-color-text .owl-dots .owl-dot span::before {
  background-color: #222222;
}

.home-slides-three.owl-theme.black-color-text .owl-dots .owl-dot:hover span,
.home-slides-three.owl-theme.black-color-text .owl-dots .owl-dot.active span {
  border-color: #222222;
}

.home-slides-three.owl-theme.bottom-carousel-dots .owl-dots {
  right: 0;
  left: 0;
  top: auto;
  -webkit-transform: unset;
  transform: unset;
  bottom: 25px;
}

.home-slides-three.owl-theme.bottom-carousel-dots .owl-dots .owl-dot {
  display: inline-block;
}

.home-slides-three.owl-theme.bottom-carousel-dots .owl-dots .owl-dot span {
  margin-left: 3px !important;
  margin-right: 3px !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.covid-19-banner {
  height: 700px;
  position: relative;
}

.covid-19-banner.covid-19-item-bg1 {
  background-image: url(../images/main-banner-image/banner-new-bg17.jpg);
}

.covid-19-banner.covid-19-item-bg2 {
  background-image: url(../images/main-banner-image/banner-new-bg18.jpg);
}

.covid-19-banner .main-banner-content p {
  line-height: 1.6;
  margin-bottom: 30px;
}

.instagram-title {
  text-align: center;
  margin-bottom: 30px;
}

.instagram-title a {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 32px;
  font-size: 15px;
  font-weight: 300;
  color: #222222;
}

.offer-area-two {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 100px;
  padding-bottom: 100px;
}

.offer-area-two.offer-bg-image {
  background-image: url(../images/offer-bg3.jpg);
}

.offer-area-two .offer-content-box {
  max-width: 720px;
  margin-right: auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.offer-area-two .offer-content-box span {
  display: block;
  color: #fff;
  font-weight: 300;
  font-size: 30px;
}

.offer-area-two .offer-content-box h2 {
  margin: 10px 0;
  color: #fff;
  font-size: 65px;
  font-weight: 700;
}

.offer-area-two .offer-content-box p {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
}

.offer-area-two .offer-content-box .btn-primary {
  background-color: #dc3545;
  border-color: #dc3545;
}

.offer-area-two .offer-content-box .btn-primary:hover {
  background-color: #be3340;
  border-color: #be3340;
  color: #fff;
}

.single-product-two .product-content {
  margin: 0;
  border-left: 1px solid #f7f7f7;
  border-right: 1px solid #f7f7f7;
  padding-top: 25px;
}

.single-product-two .product-content h3 {
  font-size: 18px;
  font-weight: 600;
}

.single-product-two .product-content .rating {
  margin-bottom: 25px;
}

.grocery-banner {
  height: 700px;
  background-position: center center;
  background-size: cover;
  background-image: url(../images/grocery-banner-bg.jpg);
}

.grocery-banner .main-banner-content {
  max-width: 720px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.grocery-banner .main-banner-content h1 {
  font-size: 50px;
  font-style: italic;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 18px;
}

.grocery-banner .main-banner-content p {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.grocery-banner .main-banner-content h2 {
  color: #e7160e;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 20px;
}

.grocery-banner .main-banner-content .btn-primary {
  background-color: #dc3545;
  border-color: #dc3545;
}

.grocery-banner .main-banner-content .btn-primary:hover {
  background-color: #be3340;
  border-color: #be3340;
  color: #fff;
}

.electronics-banner {
  background-image: url(../images/electronics-banner-bg.jpg);
}

.furniture-banner {
  background-image: url(../images/furniture-banner-bg.jpg);
}

.furniture-banner .main-banner-content {
  max-width: 100%;
  text-align: left;
}

/* Offer Categories CSS */
.categories-banner-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.offer-categories-box {
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
}

.offer-categories-box img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  width: 100%;
}

.offer-categories-box .content {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 25px 25px 0;
  text-align: right;
}

.offer-categories-box .content span {
  display: block;
  margin-bottom: 8px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #dc3545;
  font-weight: 600;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
}

.offer-categories-box .content h3 {
  margin-bottom: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  font-size: 30px;
  font-weight: 700;
}

.offer-categories-box .content .btn {
  background-color: transparent;
  color: #222222;
  border-color: #222222;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.offer-categories-box .content.text-white span {
  color: #ffffff;
}

.offer-categories-box .content.text-white h3 {
  color: #ffffff;
}

.offer-categories-box .link-btn {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  position: absolute;
}

.offer-categories-box:hover img {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
}

.offer-categories-box:hover .content span {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.offer-categories-box:hover .content h3 {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.offer-categories-box:hover .content .btn {
  background-color: #dc3545;
  color: #ffffff;
  border-color: #dc3545;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}

.offer-categories-box.hover-active .content span {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.offer-categories-box.hover-active .content h3 {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.offer-categories-box.hover-active .content .btn {
  background-color: #dc3545;
  color: #ffffff;
  border-color: #dc3545;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}

.offer-categories-box.overly {
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.offer-categories-box.overly::before {
  position: absolute;
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.left-categories {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.left-categories .content {
  right: auto;
  text-align: left;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.left-categories:hover {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.left-categories:hover .content {
  bottom: 15px;
}

.bg-fcfbfb {
  background-color: #fcfbfb;
}

/*================================================
All Products Area CSS
=================================================*/
.product-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}

.product-box .product-image {
  overflow: hidden;
  position: relative;
}

.product-box .product-image img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.product-box .product-image ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  position: absolute;
  top: 55%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  right: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-top: 25px;
}

.product-box .product-image ul li {
  margin-bottom: 8px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}

.product-box .product-image ul li a {
  width: 40px;
  height: 40px;
  line-height: 42px;
  background-color: #ffffff;
  color: #222222;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.product-box .product-image ul li a:hover,
.product-box .product-image ul li a:focus {
  color: #ffffff;
  background-color: #222222;
}

.product-box .product-image ul li:last-child {
  margin-bottom: 0;
}

.product-box .product-image .sale-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ff2d2d;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  z-index: 2;
}

.product-box .product-image #timer {
  position: absolute;
  left: 0;
  text-align: center;
  right: 0;
  margin: 0 auto;
  bottom: 15px;
}

.product-box .product-image #timer div {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px 15px;
  color: #222222;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
}

.product-box .product-image #timer div span {
  display: block;
  font-size: 13px;
  font-weight: normal;
  color: #666666;
}

.product-box .product-content {
  margin-top: 20px;
}

.product-box .product-content h3 {
  font-size: 18px;
  margin-bottom: 0;
}

.product-box .product-content h3 a {
  color: #222222;
}

.product-box .product-content h3 a:hover,
.product-box .product-content h3 a:focus {
  color: #222222;
}

.product-box .product-content .product-price {
  margin-top: 8px;
  margin-bottom: 10px;
}

.product-box .product-content .product-price span {
  font-size: 17px;
  display: inline-block;
}

.product-box .product-content .product-price .old-price {
  color: #858585;
  font-size: 15px;
  text-decoration: line-through;
}

.product-box .product-content .rating {
  margin-bottom: 15px;
  color: #eac11d;
}

.product-box .product-content .rating i {
  margin: 0 2px;
}

.product-box .product-content .btn {
  display: block;
  margin-left: 35px;
  margin-right: 35px;
}

.product-box:hover .product-image img,
.product-box:focus .product-image img {
  -webkit-transform: scale(1.2) rotate(4deg);
  transform: scale(1.2) rotate(4deg);
}

.product-box:hover .product-image ul,
.product-box:focus .product-image ul {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.product-box:hover .product-content .btn-light,
.product-box:focus .product-content .btn-light {
  background-color: #222222;
  color: #ffffff;
}

.single-product-item {
  margin-bottom: 30px;
  position: relative;
}

.single-product-item .product-image {
  overflow: hidden;
  position: relative;
}

.single-product-item .product-image img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.single-product-item .product-image .sale-tag {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ff2d2d;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  z-index: 2;
}

.single-product-item .product-image #timer {
  position: absolute;
  left: 0;
  text-align: center;
  right: 0;
  margin: 0 auto;
  bottom: 15px;
}

.single-product-item .product-image #timer div {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px 15px;
  color: #222222;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
}

.single-product-item .product-image #timer div span {
  display: block;
  font-size: 13px;
  font-weight: normal;
  color: #666666;
}

.single-product-item .product-content {
  margin-top: 20px;
}

.single-product-item .product-content h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}

.single-product-item .product-content h3 a {
  color: #222222;
}

.single-product-item .product-content h3 a:hover,
.single-product-item .product-content h3 a:focus {
  color: #222222;
}

.single-product-item .product-content .product-price {
  margin-top: 8px;
  margin-bottom: 12px;
}

.single-product-item .product-content .product-price span {
  font-size: 17px;
  display: inline-block;
}

.single-product-item .product-content .product-price .old-price {
  color: #858585;
  font-size: 15px;
  text-decoration: line-through;
}

.single-product-item .product-content .btn {
  padding: 10px 20px;
}

.single-product-item .product-content ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-product-item .product-content ul li {
  display: inline-block;
  margin-left: 5px;
}

.single-product-item .product-content ul li a {
  width: 35px;
  height: 35px;
  line-height: 38px;
  text-align: center;
  background-color: #ffffff;
  color: #222222;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.single-product-item .product-content ul li a:hover,
.single-product-item .product-content ul li a:focus {
  color: #ffffff;
  background-color: #222222;
}

.single-product-item .product-content ul li:first-child {
  margin-left: 0;
}

.single-product-item:hover .product-image img,
.single-product-item:focus .product-image img {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.single-product-item:hover .product-content .btn-light,
.single-product-item:focus .product-content .btn-light {
  background-color: #222222;
  color: #ffffff;
}

.popular-products-area {
  padding-bottom: 30px;
}

.products-category-tab-style-2 {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.products-category-tab-style-2 .title {
  position: absolute;
  left: 20px;
  top: 0;
}

.products-category-tab-style-2 .title h2 {
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 600;
}

.products-category-tab-style-2 .title h2 .dot {
  position: absolute;
  top: 3px;
  left: 0;
  width: 12px;
  height: 12px;
  border: 1px solid #222222;
}

.products-category-tab-style-2 .title h2 .dot::before {
  position: absolute;
  top: 0;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  background: #222222;
  margin: 1px;
}

.products-category-tab-style-2 .tabs {
  text-align: right;
  margin-bottom: 30px;
  padding-left: 0;
  list-style-type: none;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 5px;
}

.products-category-tab-style-2 .tabs li {
  display: inline-block;
  margin-right: 15px;
}

.products-category-tab-style-2 .tabs li span,
.products-category-tab-style-2 .tabs li a {
  color: #858585;
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
}

.products-category-tab-style-2 .tabs li span:hover,
.products-category-tab-style-2 .tabs li a:hover {
  color: #222222;
}

.products-category-tab-style-2 .tabs li span:hover::before,
.products-category-tab-style-2 .tabs li a:hover::before {
  width: 100%;
}

.products-category-tab-style-2 .tabs li span::before,
.products-category-tab-style-2 .tabs li a::before {
  height: 3px;
  width: 0;
  background-color: #222222;
  content: '';
  position: absolute;
  left: 0;
  bottom: -7px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.products-category-tab-style-2 .tabs li.current span,
.products-category-tab-style-2 .tabs li.current a {
  color: #222222;
}

.products-category-tab-style-2 .tabs li.current span::before,
.products-category-tab-style-2 .tabs li.current a::before {
  width: 100%;
}

.products-category-tab-style-2 .tabs li:last-child {
  margin-right: 0;
}

.product-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.product-slides.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 0;
  top: 40%;
  -webkit-transform: translateY(-40%);
  transform: translateY(-40%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: 0;
  margin: 0;
  background-color: #ffffff;
  color: #999999;
  font-size: 17px;
  -webkit-box-shadow: 0px 15px 20px -12px #000000;
  box-shadow: 0px 15px 20px -12px #000000;
  line-height: 35px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.product-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: #ffffff;
  background-color: #222222;
}

.product-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 0;
}

.product-slides.owl-theme:hover .owl-nav [class*=owl-] {
  left: -20px;
  opacity: 1;
  visibility: visible;
}

.product-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -20px;
}

.product-slides-count {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
}

.product-slides-count .slider-counter {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 2px 14px 1px;
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
}

/*================================================
Category Products Area CSS
=================================================*/
.category-boxes-area .section-title {
  background-color: transparent;
  padding: 0;
}

.category-boxes-area .section-title h2 {
  padding-left: 0;
  font-weight: 700;
}

.category-boxes-area .section-title h2 .dot {
  display: none;
}

.category-boxes {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}

.category-boxes img {
  border-radius: 5px;
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;

  /*Following Added by Nooruddin for custom fixation */
  height: 223px;
}

.category-boxes .content {
  position: absolute;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.category-boxes .content p {
  margin-bottom: 10px;
  line-height: initial;
  color: #ffffff;
}

.category-boxes .content h3 {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}

.category-boxes .content span {
  display: block;
  color: #ffffff;
  margin-top: 10px;
}

.category-boxes .content .shop-now-btn {
  margin-top: 15px;
  display: inline-block;
  border: 1px solid #ffffff;
  padding: 11px 25px;
  line-height: initial;
  border-radius: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

.category-boxes .content .shop-now-btn:hover {
  background-color: #222222;
  color: #ffffff;
  border-color: #222222;
}

.category-boxes .content.black-text p {
  color: #222222;
}

.category-boxes .content.black-text h3 {
  color: #222222;
}

.category-boxes .content.black-text span {
  color: #222222;
}

.category-boxes .content.black-text .shop-now-btn {
  border-color: #222222;
  color: #222222;
}

.category-boxes .content.black-text .shop-now-btn:hover {
  background-color: #222222;
  color: #ffffff;
  border-color: #222222;
}

.category-boxes:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.category-boxes:hover .content .shop-now-btn {
  background-color: #222222;
  color: #ffffff;
  border-color: #222222;
}

.category-boxes:hover .content.black-text .shop-now-btn {
  background-color: #222222;
  color: #ffffff;
  border-color: #222222;
}

.category-box {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.category-box img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.category-box .category-content {
  position: absolute;
  top: 50px;
  left: 30px;
}

.category-box .category-content h3 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
}

.category-box:hover img {
  -webkit-transform: scale(1.2) rotate(4deg);
  transform: scale(1.2) rotate(4deg);
}

.category-box:hover .category-content .btn-light {
  background-color: #222222;
  border-color: #222222;
  color: #ffffff;
}

/*================================================
Products Collections Area CSS
=================================================*/
.products-collections-area .woocommerce-pagination {
  margin-bottom: 30px;
}

.products-collections-listing.products-row-view .product-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  background-color: #fdfdfd;
}

.products-collections-listing.products-row-view .product-box .product-image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.products-collections-listing.products-row-view .product-box .product-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 0;
  padding: 20px;
}

/*================================================
News Area CSS
=================================================*/
.single-blog-post-box {
  margin-bottom: 30px;
}

.single-blog-post-box .post-content {
  margin-top: 25px;
}

.single-blog-post-box .post-content .post-meta {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-blog-post-box .post-content .post-meta li {
  display: inline-block;
  margin-right: 25px;
  color: #666666;
  position: relative;
}

.single-blog-post-box .post-content .post-meta li::before {
  content: '';
  position: absolute;
  right: -20px;
  top: 10px;
  width: 10px;
  height: 1px;
  background-color: #222222;
}

.single-blog-post-box .post-content .post-meta li a {
  color: #666666;
  display: inline-block;
}

.single-blog-post-box .post-content .post-meta li a:hover {
  color: #222222;
}

.single-blog-post-box .post-content .post-meta li:last-child {
  margin-right: 0;
}

.single-blog-post-box .post-content .post-meta li:last-child::before {
  display: none;
}

.single-blog-post-box .post-content h3 {
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 27px;
  font-size: 18px;
  font-weight: 600;
}

.single-blog-post-box .post-content h3 a {
  color: #222222;
}

.single-blog-post-box .post-content h3 a:hover,
.single-blog-post-box .post-content h3 a:focus {
  color: #222222;
}

/*================================================
Pagination CSS
=================================================*/
.woocommerce-pagination {
  text-align: center;
  margin-top: 10px;
}

.woocommerce-pagination ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.woocommerce-pagination ul li {
  display: inline-block;
  margin-left: 1px;
  margin-right: 1px;
}

.woocommerce-pagination ul li .page-numbers {
  display: inline-block;
  width: 35px;
  line-height: 35px;
  height: 35px;
  color: #222222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 1px solid #666666;
  font-size: 14px;
  font-weight: 600;
}

.woocommerce-pagination ul li .page-numbers.dots {
  border-color: #666666 !important;
  background-color: transparent !important;
  color: #666666 !important;
}

.woocommerce-pagination ul li .page-numbers:hover,
.woocommerce-pagination ul li .page-numbers.current {
  background-color: #222222;
  color: #ffffff;
  border-color: #222222;
}

/* End home Six, Seven, Eight, Nine, Ten & Eleven Style */



.rating-side-bar {
  margin-bottom: 12px;
  color: #eac11d;
}


.products-view-all {
  float: right;
}

.products-view-all a {
  border: 1px solid #222;
  color: #222;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;

}


.products-view-all a:hover {
  color: #fff;
  background-color: #222;
}

.required-field {
  color: red;
}

.cart-coupon-code {
  border: 2px solid #5cca5c !important;

}

.product-color-cell {
  height: 23px;
  width: 23px;
  /* background-color: red; */
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  cursor: pointer;
}

.product-color-cell:hover {
  height: 23px;
  width: 23px;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid white;
  box-shadow: 0 0 0 1px black;
  cursor: pointer;
}

.product-color-cell-active {
  border: 2px solid white;
  box-shadow: 0 0 0 1px black;
}

.pagination-li-item-custom {
  margin: 0 2px;
}


/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 14px;
  left: 15px;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 50px;
    height: 50px;
    bottom: 22px;
    left: 10px;
    font-size: 22px;
  }
}

.text-align-left {
  text-align: left;
}

.product-variant-title {
  font-size: 15px !important;
  margin-bottom: 8px !important;
  color: #4861ba !important;
}

.product-var-product-btn{
  background: #9494b966;
  border-radius: 2px;
  padding: 4px 6px 4px 6px;
  color: black !important;
}